function removeSpacesInNegativeParentheses(str) {
  let result = "";
  let stack = [];
  let i = 0;

  while (i < str.length) {
    if (str[i] === "-" && str[i + 1] === "(") {
      // Start of a `-()` group
      result += "-(";
      stack.push("(");
      i += 2;

      let innerContent = "";
      while (stack.length > 0 && i < str.length) {
        if (str[i] === "(") {
          stack.push("(");
          innerContent += "(";
        } else if (str[i] === ")") {
          stack.pop();
          if (stack.length > 0) {
            innerContent += ")";
          }
        } else {
          innerContent += str[i];
        }
        i++;
      }

      // Remove spaces within the captured content
      innerContent = innerContent.replace(/\s+/g, "");
      result += innerContent + ")";
    } else {
      result += str[i];
      i++;
    }
  }

  return result;
}

function cleanString(str) {
  // Step 1: Split the string by spaces
  let splitArray = str.split(" ").filter(Boolean);

  // Step 2: Filter out elements that start with "-" or are in the form "-(...)".
  let filteredArray = splitArray.filter((item) => {
    // Remove if it starts with "-" or is in the form of "-(...)"
    return !(item.startsWith("-") || item.startsWith("-("));
  });

  // Step 3: Join the remaining valid parts back into a string
  return filteredArray.join(" ");
}

export function removeNegativeValues(str) {
  let removeSpaceStr = removeSpacesInNegativeParentheses(str);
  let filteredSkillValue = cleanString(removeSpaceStr);

  return filteredSkillValue;
}

export function javaDeveloperSkillCLusterLabelUpdation(value) {
  let label;
  if (
    value ===
    "Java (scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS"
  ) {
    label = "Java, Scaleable apps";
  } else if (
    value ===
    "Java (SQL OR(Mysql)) Spring -(scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS"
  ) {
    label = "Java, SQL, Spring  ";
  } else if (
    value ===
    "Java Spring (Rest OR(Restful) OR(API) OR(webservice)) -MYSQL -(scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS"
  ) {
    label = "Java, Spring, Webservice";
  } else if (
    value ===
    "Java Spring datastructures -(Rest OR(Restful) OR(API) OR(webservice)) -MYSQL -(scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS"
  ) {
    label = "Java, Spring, Datastructures";
  } else if (
    value ===
    "Java (Javascript  OR(Code)) -Spring -datastructures -(Rest OR(Restful) OR(API) OR(webservice)) -MYSQL -(scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS"
  ) {
    label = "Java, JS";
  } else if (
    value ===
    "Java Spring -datastructures -(Rest OR(Restful) OR(API) OR(webservice)) -MYSQL -(scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS"
  ) {
    label = "Java, Spring";
  } else if (
    value ===
    "Java DATABASE -(Javascript  OR(Code)) -Spring -datastructures -(Rest OR(Restful) OR(API) OR(webservice)) -MYSQL -(scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS"
  ) {
    label = "Java, Database";
  } else if (
    value ===
    "Java Spring boot Aws microservices (mongodb OR(MONGO) OR(NoSQL)) -Kubernetes "
  ) {
    label = "Java, SpringBoot, AWS, Microservices, NoSQL";
  } else if (
    value ===
    "Java Spring boot Aws microservices maven -(mongodb OR(MONGO) OR(NoSQL)) -Kubernetes "
  ) {
    label = "Java, SpringBoot, AWS, Microservices, CI/CD";
  } else if (
    value ===
    "Java Spring boot Aws microservices -maven -(mongodb OR(MONGO) OR(NoSQL)) -Kubernetes "
  ) {
    label = "Java, SpringBoot, AWS, Microservices";
  } else if (
    value ===
    "Java Spring boot Aws (mongodb OR(MONGO) OR(NoSQL)) -microservices -Kubernetes "
  ) {
    label = "Java, SpringBoot, AWS, NoSQL";
  } else if (
    value ===
    "Java Spring boot Aws (ci OR(cd) OR(Maven)) -(mongodb OR(MONGO) OR(NoSQL)) -microservices -Kubernetes "
  ) {
    label = "Java, SpringBoot, AWS, CI/CD";
  } else if (
    value ===
    "Java Spring boot Aws -(ci OR(cd) OR(Maven)) -(mongodb OR(MONGO) OR(NoSQL)) -microservices -Kubernetes "
  ) {
    label = "Java, SpringBoot, AWS";
  } else if (
    value ===
    "Java Spring boot Aws microservices (mongodb OR(MONGO) OR(NoSQL))  -Kubernetes "
  ) {
    label = "Java, SpringBoot, AWS, Microservices, NoSQL";
  } else if (
    value ===
    "Java Spring boot (mongodb OR(MONGO) OR(NoSQL)) (rest OR(restful)) -Aws -Kubernetes "
  ) {
    label = "Java, SpringBoot, Rest, NoSQL";
  } else if (
    value ===
    "Java Spring boot (mongodb OR(MONGO) OR(NoSQL)) -(rest OR(restful)) -Aws -Kubernetes "
  ) {
    label = "Java, SpringBoot, NoSQL";
  } else if (
    value ===
    "Java Spring boot maven (rest OR(restful)) -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes "
  ) {
    label = "Java, SpringBoot, Rest, CI/CD";
  } else if (
    value ===
    "Java Spring boot maven -(rest OR(restful)) -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes "
  ) {
    label = "Java Spring boot maven";
  } else if (
    value ===
    "Java Spring boot (rest OR(restful)) webservices -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes "
  ) {
    label = "Java, SpringBoot, Rest";
  } else if (
    value ===
    "Java Spring boot (rest OR(restful)) -webservices -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes "
  ) {
    label = "Java, SpringBoot, Rest";
  } else if (
    value ===
    "Java Spring boot (rest OR(restful)) -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes "
  ) {
    label = "Java SpringBoot, Rest";
  } else if (
    value ===
    "Java Spring boot (sql OR(mysql)) html -(rest OR(restful)) -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes "
  ) {
    label = "Java, SpringBoot, SQL";
  } else if (
    value ===
    "Java Spring boot (sql OR(mysql)) -html -(rest OR(restful)) -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes "
  ) {
    label = "Java, SpringBoot, SQL";
  } else if (
    value ===
    "Java Spring boot -(sql OR(mysql)) -(rest OR(restful)) -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes "
  ) {
    label = "Java Spring boot";
  } else if (
    value ===
    "Java Spring boot Aws microservices -(mongodb OR(MONGO) OR(NoSQL)) -Kubernetes "
  ) {
    label = "Java Spring boot Aws Microservices";
  } else if (value === "Java spring Kubernetes (mongo OR Mongodb)") {
    label = "Java, Spring, Kubernetes, MongoDB";
  } else if (value === "Java spring Kubernetes -(mongo OR Mongodb)") {
    label = "Java, Spring, Kubernetes";
  } else if (
    value ===
    "Java spring (API OR(rEST) OR(Restful) OR(Webservices))  -AWS -boot -Microservices -Kubernetes"
  ) {
    label = "Java, Spring, API";
  } else if (
    value ===
    "Java spring MYSQL -(API OR(rEST) OR(Restful) OR(Webservices))  -AWS -boot -Microservices -Kubernetes"
  ) {
    label = "Java, Spring, MysQL";
  } else if (
    value ===
    "Java spring -MYSQL -(API OR(rEST) OR(Restful) OR(Webservices))  -AWS -boot -Microservices -Kubernetes"
  ) {
    label = "Java spring";
  } else if (
    value ===
    "Java spring -MYSQL -(API OR(rEST) OR(Restful) OR(Webservices))  -AWS -boot -Microservices -Kubernetes"
  ) {
    label = "Java spring";
  } else if (
    value ===
    "Java -spring -MYSQL -(API OR(rEST) OR(Restful) OR(Webservices))  -AWS -boot -Microservices -Kubernetes"
  ) {
    label = "Java";
  } else if (
    value === "Java Spring boot database -(sql OR(mysql)) -microservices -aws "
  ) {
    label = "Java Spring boot database";
  } else if (
    value ===
    "Java Spring boot (sql OR(mysql)) (hibernate OR JPA OR jdbc OR orm) -(rest OR restful OR restapi) -microservices -aws "
  ) {
    label =
      "Java Spring boot (sql OR(mysql)) (hibernate OR JPA OR jdbc OR orm)";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function dataScientistSkillCLusterLabelUpdation(value) {
  let label;
  if (value === "deep (tensorflow OR tensor) (ML OR machine learning)") {
    label = "Deep Learning, Tensorflow, ML";
  } else if (
    value === "deep (ML OR machine learning) -(tensorflow OR tensor) "
  ) {
    label = "Deep Learning, ML";
  } else if (
    value ===
    "(ML OR(machine learning)) (genAI OR(gen AI) OR(generative) OR(llm) OR(language model)) -Deep "
  ) {
    label = "ML, GenAI/LLM";
  } else if (
    value ===
    "(NLP OR(NLTK) OR(Natural)) (ML OR(Machine Learning)) -(genAI OR(gen AI) OR(llm) OR(Language Model)) -deep"
  ) {
    label = "NLP/NLTK, ML";
  } else if (
    value ===
    "(Model OR(Pandas) OR(Scikit)) (ML OR(Machine Learning)) -(NLP OR(NLTK) OR(Natural)) -(genAI OR(gen AI) OR(llm) OR(Language Model)) -deep"
  ) {
    label = "Data Models, ML";
  } else if (
    value ===
    "(ML OR(Machine Learning)) -(NLP OR(NLTK) OR(Natural)) -(genAI OR(gen AI) OR(llm) OR(Language Model)) -deep -(Model OR(Pandas) OR(Scikit)) "
  ) {
    label = "ML";
  } else if (
    value ===
    "(statistics OR statistical OR predictive) (analytics OR analytical OR modelling) -(ML OR(Machine Learning))"
  ) {
    label = "Statistics/Predictive, Analytics";
  } else if (
    value ===
    "(analytics OR analytical OR modelling) -(ML OR(Machine Learning)) -(statistics OR statistical OR predictive)"
  ) {
    label = "Analytics";
  } else if (value === "deep (tensorflow OR tensor) ") {
    label = "Deep Learning, Tensorflow";
  } else if (value === "deep  -(tensorflow OR tensor)") {
    label = "Deep Learning";
  } else if (value === "(tensorflow OR tensor) -Deep") {
    label = "Tensorflow";
  } else if (
    value === "(NLP OR(NLTK) OR(Natural)) -(tensorflow OR tensor) -Deep"
  ) {
    label = "NLP/NLTK";
  } else if (
    value ===
    "(genAI OR(gen AI) OR(generative) OR(llm) OR(language model)) -(NLP OR(NLTK) OR(Natural)) -(tensorflow OR(tensor)) -Deep"
  ) {
    label = "GenAI/LLM";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function mernStackSkillClusterLabelUpdation(value) {
  let label;
  if (
    value ===
    "(fullstack OR(Full stack) OR(MERN)) (Node OR(Nodejs)) (express OR(Expressjs)) (react OR(ReactJS)) -spring -asp"
  ) {
    label = "Nodejs, expressjs, reactjs";
  } else if (
    value ===
    "(fullstack OR(Full stack) OR(MERN)) (Node OR(Nodejs)) (express OR(Expressjs)) (react OR(ReactJS)) redux -spring -asp"
  ) {
    label = "Nodejs, expressjs, reactjs, redux";
  } else if (
    value ===
    "(fullstack OR(Full stack) OR(MERN)) (Node OR(Nodejs)) (express OR(Expressjs)) (react OR(ReactJS)) -redux -spring -asp"
  ) {
    label = "Nodejs, expressjs, reactjs";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function productManagerSkillClusterLaelUpdation(value) {
  let label;
  if (
    value ==
      '("software engineer" OR("Software developer") OR("System Engineer") OR("Software development engineer"))' ||
    value ==
      '("software engineer" OR "Software developer" OR "System Engineer" OR "Software development engineer")' ||
    value ==
      '"software engineer" OR "Software developer" OR "System Engineer" OR "Software development engineer"'
  ) {
    label = "Past : Software Engineer";
  } else if (
    value ==
    '(Rest OR(Restful) OR(Restapi) OR(API) OR(Graphql)) ("software engineer" OR("Software developer") OR("System Engineer") OR("Software development engineer"))'
  ) {
    label = "API, Past : Software Engineer";
  } else if (
    value ==
    `(Java OR Nodejs OR Python) (\"software engineer\" OR(\"Software developer\") OR(\"System Engineer\") OR(\"Software development engineer\")) -(Rest OR(Restful) OR(Restapi) OR(API) OR(Graphql))`
  ) {
    label = "Any Language, Past : Software Engineer";
  } else if (
    value ==
    `(SQL OR(Mysql)) (\"software engineer\" OR(\"Software developer\") OR(\"System Engineer\") OR(\"Software development engineer\")) -(Rest OR(Restful) OR(Restapi) OR(API) OR(Graphql)) -(Java OR(Nodejs) OR(Python))`
  ) {
    label = "Database, Past : Software Engineer";
  } else if (
    value ==
    `(\"software engineer\" OR(\"Software developer\") OR(\"System Engineer\") OR(\"Software development engineer\")) -(Rest OR(Restful) OR(Restapi) OR(API) OR(Graphql)) -(Java OR(Nodejs) OR(Python)) -(SQL OR(Mysql))`
  ) {
    label = "Past : Software Engineer";
  } else if (value === "(User OR(Research)) (Data OR(Analytics))") {
    label = "User Research, Data/Analytics";
  } else if (value === "(User OR(Research)) -(Data OR(Analytics))") {
    label = "User Research";
  } else if (value === "(Data OR(Analytics)) -(User OR(Research))") {
    label = "Data/Analytics";
  } else if (
    value === "(Speech OR(voice) OR(Conversational) OR(NLP) OR(ML) OR(AI))"
  ) {
    label = "Speech/Voice/NLP/AI/ML";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function dataEngineerSkillClusterUpdation(value) {
  let label;
  if (value === "Python (etl OR(informatica)) azure") {
    label = "Python, ETL, Azure";
  } else if (value === "Python (etl OR(informatica)) AWS -azure") {
    label = "Python, ETL, AWS";
  } else if (value === "Python (etl OR(informatica)) -AWS -azure") {
    label = "Python, ETL";
  } else if (value === "Python -(etl OR(informatica)) -AWS -azure") {
    label = "Python";
  } else if (value === "Python pyspark -(etl OR(informatica)) ") {
    label = "Python, Pyspark";
  } else if (value === "Python -pyspark -(etl OR(informatica))") {
    label = "Python";
  } else if (value === "(dwh OR ETL OR warehouse) -Python ") {
    label = "DWH";
  } else if (
    value === "(AZURE OR(AWS) OR(Cloud)) -(dwh OR(ETL) OR(warehouse)) -Python "
  ) {
    label = "Cloud";
  } else if (
    value ===
    "(sql OR(mysql) OR(database)) -(AZURE OR(AWS) OR(Cloud)) -(dwh OR(ETL) OR(warehouse)) -Python"
  ) {
    label = "SQL";
  } else if (value === '"Data Engineer"') {
    label = "others";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function testEngineerSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    'Java Selenium (Rest assured OR Restassured) (testng OR "Test ng")'
  ) {
    label = "Java, Selenium, Rest Assured, TestNG";
  } else if (
    value ===
    'Java Selenium (Rest assured OR Restassured) (testng OR "Test ng") Postman'
  ) {
    label = "Java, Selenium, Rest Assured, TestNG";
  } else if (
    value ===
    'Java Selenium (Rest assured OR Restassured) (testng OR "Test ng") -Postman'
  ) {
    label = "Java, Selenium, Rest Assured, TestNG, Testing Tools";
  } else if (value === "Automation (javascript OR(typescript)) API MySQL") {
    label = "Automation, Javascript/Typescript, API, SQL";
  } else if (
    value === "Automation (javascript OR(typescript)) API Rest -MySQL"
  ) {
    label = "Automation, Javascript/Typescript, Rest API, SQL";
  } else if (
    value === "Automation (javascript OR(typescript)) API -Rest -MySQL"
  ) {
    label = "Automation, Javascript/Typescript, API, SQL";
  } else if (value === "Automation (javascript OR(typescript)) MySQL -API ") {
    label = "Automation, Javascript/Typescript, MySQL";
  } else if (value === "Automation (javascript OR(typescript)) -MySQL -API ") {
    label = "Automation, Javascript/Typescript";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function iosDeveloperSkillClusterUpdation(value) {
  let label;
  if (value === "Swift Senior") {
    label = "iOS, Swift";
  } else if (
    value === "(Objective OR ObjectiveC) swift (angular OR Angularjs)"
  ) {
    label = "Objective-C, Swift, Angularjs";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function salesForceDeveloperSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "(Salesforce OR(SFDC)) Apex (Triggers OR Trigger) (LWC OR Lightning) "
  ) {
    label = "SFDC, Apex, Triggers, LWC";
  } else if (
    value ===
    "(Salesforce OR(SFDC)) Apex (LWC OR Lightning) -(Triggers OR Trigger) "
  ) {
    label = "SFDC, Apex, Lightning, Salesforce Stack";
  } else if (
    value ===
    "(Salesforce OR(SFDC)) (LWC OR Lightning) (java OR .net) (Triggers OR Trigger)"
  ) {
    label = "SFDC, java/.net, Lightning, trigger";
  } else if (
    value ===
    "(Salesforce OR(SFDC)) (LWC OR Lightning) (java OR .net) -apex -(Triggers OR Trigger)"
  ) {
    label = "SFDC, java/.net, Lightning";
  } else if (
    value ===
    "(Salesforce OR(SFDC)) (LWC OR Lightning) (java OR .net) apex -(Triggers OR Trigger)"
  ) {
    label = "SFDC, java/.net, Lightning, apex";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function pythonFullstackSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "(fullstack OR(Full stack)) Python DJANGO (Flask OR(FAST) OR(FastAPI)) (React OR(ReactJS)) -c# -Spring"
  ) {
    label = "Python, DJANGO, ReactJS";
  } else if (
    value ===
    "Python DJANGO (Flask OR(FAST) OR(FastAPI)) (React OR(ReactJS)) -c# -Spring -(fullstack OR(Full stack))"
  ) {
    label = "Python, DJANGO, ReactJS, Python Libraries";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function javaFullstackSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "Java Spring JDBC (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) "
  ) {
    label = "Java, Spring, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring Hibernate MVC (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC"
  ) {
    label = "Java, Spring MVC, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring Hibernate (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -MVC"
  ) {
    label = "Java, Spring, Hibernate, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring boot mysql (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate"
  ) {
    label = "Java, Spring, SQL, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring boot (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate -mysql"
  ) {
    label = "Java, Spring boot, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring AWS (SQL OR(MySQL)) (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate -boot"
  ) {
    label = "Java, Spring, AWS, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate -boot -AWS -c# -cloud"
  ) {
    label = "Java, Spring, ReactJS/AngularJS, JS";
  } else if (
    value ===
    "Java Spring AWS (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate -boot -(SQL OR(MySQL))"
  ) {
    label = "Java, Spring, DB, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring cloud (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate -boot -AWS -c#"
  ) {
    label = "Java, Spring, Cloud, ReactJS/AngularJS";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function flutterSkillClusterUpdation(value) {
  let label;
  if (value === "flutter Dart (Riverpod OR river pod OR Bloc)") {
    label = "flutter, Dart, Riverpod/Bloc";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function networkEngineerSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "(SDLAN OR WLAN OR SDWAN OR WAN) (routing OR router OR switching) cisco"
  ) {
    label = "SDLAN /WLAN /SDWAN /WAN, routing, switching";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function linuxAdministratorSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "Linux ((administrator) OR(administration) OR(admin)) ((AIX AND Unix) OR (AIX AND redhat) OR (Unix AND redhat)) RHEL"
  ) {
    label = "Linux, AIX, Unix, Redhat, RHEL";
  } else if (
    value ===
    "Linux ((administrator) OR(administration) OR(admin)) ((AIX AND Unix) OR (AIX AND redhat) OR (Unix AND redhat)) (incident OR(change))  -RHEL"
  ) {
    label = "Linux, AIX, Unix, Redhat, incident/change mgmt";
  } else if (
    value ===
    "Linux ((administrator) OR(administration) OR(admin)) ((AIX AND Unix) OR (AIX AND redhat) OR (Unix AND redhat)) (certified OR(certification)) -(incident OR(change))  -RHEL"
  ) {
    label = "Linux, AIX, Unix, Redhat, certified";
  } else if (
    value ===
    "Linux ((administrator) OR(administration) OR(admin)) ((AIX AND Unix) OR (AIX AND redhat) OR (Unix AND redhat)) -(certified OR(certification)) -(incident OR(change))  -RHEL"
  ) {
    label = "Linux, AIX, Unix, Redhat";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function pythonDeveloperSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "python (django OR(flask)) (rest OR(restful) OR(restapi)) (LLM OR language model  OR GenAI OR GAI OR generative AI OR GEN AI)"
  ) {
    label = "Python, Django/flask, rest API, GAI/Generative AI/LLM";
  } else if (value === "python django aws s3 rds -spring -angular -react") {
    label = "python, Django, AWS, S3, RDS";
  } else if (value === "python django aws s3 -rds -spring -angular -react") {
    label = "python, Django, AWS, S3";
  } else if (
    value ===
    "python django aws (rest OR(restful) OR(api)) (nosql OR(mongo) OR(mongodb)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, rest/API, mongoDB";
  } else if (
    value ===
    "python django aws (rest OR(restful) OR(api)) (database OR db) -(nosql OR(mongo) OR(mongodb)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, rest/API, Databases";
  } else if (
    value ===
    "python django aws (rest OR(restful) OR(api)) -(database OR db) -(nosql OR(mongo) OR(mongodb)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, rest/API";
  } else if (
    value ===
    "python django aws (sql OR(mysql)) (architecture OR(design) OR(designing)) -(database OR(db)) -(rest OR(restful) OR(api)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, sql/mysql, architecture/design";
  } else if (
    value ===
    "python django aws (sql OR(mysql)) -(architecture OR(design) OR(designing)) -(database OR(db)) -(rest OR(restful) OR(api)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, sql";
  } else if (
    value ===
    "python django aws systems -(sql OR(mysql)) -(rest OR(restful) OR(api)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, systems";
  } else if (
    value ===
    "python django aws -systems -(sql OR(mysql)) -(rest OR(restful) OR(api)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS";
  }

  // else if(value === ){
  //   label = ""
  // }
  else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function uiDeveloperSkillClusterUpdation(value) {
  let label;
  if (value === "(react OR redux) html5") {
    label = "React, Redux, html5";
  } else if (value === "(react OR redux) -html5") {
    label = "React, Redux";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}
