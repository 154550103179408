import React from "react";
import { Typography, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./Animation3.module.css";

const StepHeading = () => {
  const Title = Typography.Title;

  return (
    <div className={styles.step1__top__heading}>
      <div className={styles.step1__heading}>
        <Title level={4} style={{ margin: "0" }}>
          {"Candidate Outreach"}
        </Title>
      </div>

      <div>
        <Button
          type="primary"
          size="large"
          style={{
            height: "25px",
            fontSize: "10px",
            background: "#fff",
            color: "#0778BD",
            border: "1.25px solid #0778BD",
          }}
        >
          Save progress and Close
        </Button>
      </div>
    </div>
  );
};

export default StepHeading;
