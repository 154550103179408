import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loginSuccess,
  logoutSuccess,
  setAdminToken,
  setformSubmitLoader,
  setGrantAccess,
  setIsAdmin,
  setLoadingButtons,
  setOpenAddedToWaitlistModal,
  setOpenLoginModal,
  setWaitListFormModal,
  setWishListProfiles,
} from "../features/auth2/authSlice2";
import { message } from "antd";
import { decodeJWT_Token } from "../components/NewDesign/sharedComponents/Waitlist_form/TokenEncryption";

function useAuthData() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminToken = useSelector((state) => state.auth2.adminToken);
  const profiles = useSelector((state) => state.auth2.wishListProfiles);

  const updateProfileAccess = (id) => {
    return profiles.map((profile) =>
      profile._id === id ? { ...profile, isEnabled: true } : profile
    );
  };

  const getUserDetails = async (token) => {
    dispatch(setWaitListFormModal(false));
    dispatch(setOpenLoginModal(false)); // Close login modal if open
    try {
      const apiResponse = await axios({
        method: "GET",
        baseURL: BASE_URL,
        url: "/v1/company/profile-details",
        headers: {
          Authorization: "jwt " + token, // Attach token in headers
        },
      });

      const { email, firstName, lastName, referralCode } =
        apiResponse.data.data;
      dispatch(loginSuccess({ email, firstName, lastName, referralCode }));
      localStorage.setItem("token", token);
      navigate("/live-sourcing");
      message.success("Logged In Successfully!");
    } catch (error) {
      console.error("Error fetching user details:", error.message);
      dispatch(logoutSuccess());
      localStorage.removeItem("token");
      if (error?.response?.status === 401) {
        message.error("Invalid access token!!");
      }
    }
  };

  const checkIsUserDetailsAdded = async (token) => {
    dispatch(setOpenLoginModal(false)); // Close login modal if open

    try {
      const apiResponse = await axios({
        method: "GET",
        baseURL: BASE_URL,
        url: "/v1/company/profile-details",
        headers: {
          Authorization: "jwt " + token, // Attach token in headers
        },
      });

      const userDetails = apiResponse.data.data;

      // Check if the "linkedinUrl" field exists
      const isLinkedinUrlPresent = "linkedinUrl" in userDetails;

      // Return result
      return !isLinkedinUrlPresent;
    } catch (error) {
      console.error("Error fetching user details:", error.message);
      dispatch(logoutSuccess());
      localStorage.removeItem("token");
      // navigate("/");
      return true; // Assume fields are not filled if the API call fails
    }
  };

  const submitCouponCode = async (token, coupen_code) => {
    const userID = decodeJWT_Token(token);

    try {
      const apiResponse = await axios({
        method: "GET",
        baseURL: BASE_URL,
        url: `/v1/company/users/${userID}/verify?coupon=${coupen_code}`,
        headers: {
          Authorization: "jwt " + token,
        },
      });
      dispatch(setformSubmitLoader(false));
      navigate(`success?accessToken=${token}`);
    } catch (error) {
      dispatch(setformSubmitLoader(false));
      console.error("Error fetching user details:", error.message);
      if (error?.response?.status === 400) {
        message.error("Invalid Refferal Code!");
      } else if (error?.response?.status === 401) {
        message.error("Invalid access token!!");
        navigate("/");
      } else {
        message.error("Error in applying Refferal Code!");
      }
    }
  };

  const updateUserDetails = async (token, data, referralCode) => {
    dispatch(setformSubmitLoader(true));
    try {
      const apiResponse = await axios({
        method: "PUT",
        baseURL: BASE_URL,
        url: "/v1/company/profile-details",
        headers: {
          Authorization: "jwt " + token,
        },
        data,
      });
      if (referralCode !== "") {
        submitCouponCode(token, referralCode);
      } else {
        dispatch(setformSubmitLoader(false));
        dispatch(setOpenAddedToWaitlistModal(true));
        navigate("/");
        dispatch(setWaitListFormModal(false));
      }
    } catch (error) {
      dispatch(setformSubmitLoader(false));
      console.error("Error fetching user details:", error.message);
      dispatch(setWaitListFormModal(false));
      navigate("/");
      if (error?.response?.status === 401) {
        message.error("Invalid access token!!");
        dispatch(logoutSuccess());
      } else {
        message.error(
          "Facing Error in submitting basic details, try again later!!"
        );
      }
    }
  };

  const checkAdmin = async (data) => {
    dispatch(setLoadingButtons(true));
    try {
      const apiResponse = await axios({
        method: "POST",
        baseURL: BASE_URL,
        url: "/v1/auth/login-admin",
        data,
      });
      message.success("Admin Access Granted");
      dispatch(setLoadingButtons(false));
      dispatch(setAdminToken(apiResponse.data.token));
      dispatch(setIsAdmin(true));
      navigate("/admin");
    } catch (error) {
      dispatch(setLoadingButtons(false));
      console.error("Error getting admin access:", error.message);
      if (error?.response?.status === 401) {
        message.error("Invalid credentials!!");
      }
    }
  };

  const getWishlistUser = async (page, pageCount) => {
    dispatch(setLoadingButtons(true));
    try {
      const apiResponse = await axios({
        method: "GET",
        baseURL: BASE_URL,
        url: `/v1/admin/users?pageNumber=${page}&pageSize=${pageCount}`,
        headers: {
          Authorization: `jwt ${adminToken}`,
        },
      });
      let totalPages = apiResponse.data.totalPages;
      let profiles = apiResponse.data.data;
      dispatch(setWishListProfiles({ profiles, totalPages }));
      dispatch(setLoadingButtons(false));
    } catch (error) {
      dispatch(setLoadingButtons(false));
      message.error("Error getting wishlist user:", error.message);
      if (error?.response?.status === 401) {
        message.error("Session expired!");
        dispatch(setAdminToken(null));
        navigate("/admin");
      }
    }
  };

  const grantAppAccess = async (userId) => {
    dispatch(setGrantAccess(true));
    try {
      const apiResponse = await axios({
        method: "PUT",
        baseURL: BASE_URL,
        url: `/v1/admin/users/${userId}`,
        headers: {
          Authorization: `jwt ${adminToken}`,
        },
        data: {
          isEnabled: true,
        },
      });
      let updatedProfiles = updateProfileAccess(userId);
      dispatch(setWishListProfiles(updatedProfiles));
      dispatch(setGrantAccess(false));
      message.success("Access Granted");
    } catch (error) {
      dispatch(setGrantAccess(false));
      message.error("Error granting app access:");
      if (error?.response?.status === 401) {
        message.error("Session expired!");
        dispatch(setAdminToken(null));
        navigate("/admin");
      }
    }
  };

  return {
    getUserDetails,
    checkIsUserDetailsAdded,
    updateUserDetails,
    submitCouponCode,
    checkAdmin,
    getWishlistUser,
    grantAppAccess,
  };
}

export default useAuthData;
