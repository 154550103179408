import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Typography,
  Chip,
  Box,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAuthData from "../../../../api/useAuthData";
import { decryptedToken } from "./TokenEncryption";
import { message, Spin } from "antd";
import { setformSubmitLoader, setWaitListFormModal } from "../../../../features/auth2/authSlice2";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const fields = [
  { label: "LinkedIn URL", name: "linkedInUrl", required: true },
  { label: "Current Company", name: "currentCompany", required: true },
  { label: "Current Designation", name: "designation", required: true },
  { label: "Phone Number (WhatsApp)", name: "phoneNumber", required: true },
];

export default function WaitListForm() {
  const { checkIsUserDetailsAdded, submitCouponCode, updateUserDetails } =
    useAuthData();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showFullForm, setShowFullForm] = useState(true);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [token, setToken] = useState("");
  const isAuth = useSelector((state) => state.auth2.isAuthorized);
  const showModal = useSelector((state) => state.auth2.waitListFormModal);

  const locationOptions = [
    "Bengaluru",
    "Chennai",
    "Delhi/NCR",
    "Gurgaon",
    "Hyderabad",
    "Pune",
    "Mumbai",
    "Others",
  ];

  
  useEffect(() => {
    async function checkFormFilled(){
      if (isAuth || !showModal) {
        navigate("/");
      } else {
        const rawToken =
          searchParams.get("accessToken") || localStorage.getItem("token");
        if (rawToken) {
          const encryptedToken = encodeURIComponent(rawToken)
            .replace(/%3A/g, ":")
            .replace(/%2F/g, "/")
            .replace(/%20/g, "+")
            .replace(/%3D/g, "=");
          const decryptToken = decryptedToken(encryptedToken);
          if(decryptToken === "Decryption Error"){
            dispatch(setWaitListFormModal(false));
            message.error("Invalid access token")
            navigate("/");
            return;
          }
          setToken(decryptToken);
          const showFullForm = await(checkIsUserDetailsAdded(decryptToken));
          setShowFullForm(showFullForm);
          setLoadingDetails(false);
        }else{
          navigate("/")
        }
      }
    }


    checkFormFilled();
  }, []);

  
  const [formData, setFormData] = useState({
    linkedInUrl: "",
    currentCompany: "",
    phoneNumber: "",
    designation: "",
    hiringLocations: [],
    hiringLocationsInput: "",
    referralCode: "",
  });
  const [errors, setErrors] = useState({});
  const loading = useSelector((state) => state.auth2.formSubmitLoader);

  const handleClose = () => {
    message.error("Fill out the form first and submit it!!");
  };

  const handleCloseRefferalForm = () => {
    dispatch(setWaitListFormModal(false));
    setErrors({});
    navigate("/");
    dispatch(setWaitListFormModal(false));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Create a new errors object
    let newErrors = { ...errors };

    // Check if the field is required
    if (fields.some((field) => field.name === name && field.required)) {
      if (!value.trim()) {
        newErrors[name] = `${name} is required`;
      } else {
        delete newErrors[name];
      }
    }

    // Handle phone number field (allow only numeric values)
    if (name === "phoneNumber") {
      // Remove any non-numeric characters from the value
      const numericValue = value.replace(/\D/g, ""); // Replaces non-numeric characters with an empty string

      if (numericValue !== value) {
        // If there were any non-numeric characters, set an error
        newErrors[name] = "Phone number can only contain numbers.";
      } else {
        // No error for valid numeric input
        delete newErrors[name];
      }

      // Update the value to contain only digits
      setFormData((prev) => ({ ...prev, [name]: numericValue }));
    }

    // Update errors state
    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors = {};

    fields.forEach(({ name, required }) => {
      let value = formData[name];

      if (required && !value.trim()) {
        newErrors[name] = `${name} is required`;
      }

      if (name === "phoneNumber" && value.trim()) {
        const phoneRegex = /^\d{10}$/; // Strictly 10 numeric characters
        if (!phoneRegex.test(value)) {
          newErrors[name] =
            "Phone number must contain 10 digits without special characters.";
        }
      }

      if (name === "linkedInUrl" && value.trim()) {
        const linkedInRegex = /^(https?:\/{0,2})?(www\.)?linkedin\.com\/.*$/;
        if (!linkedInRegex.test(value)) {
          newErrors[name] = "Invalid LinkedIn URL";
        } else {
          // Optionally normalize the URL
          value = value.replace(/^(https?:\/{0,2})?/, "https://"); // Normalize the URL to standard format
        }
      }
      
    });

    // Validation for hiringLocations
    if (formData.hiringLocations.length === 0) {
      newErrors.hiringLocations = "At least one location is required";
    }

    setErrors(newErrors);
    console.log(Object.keys(newErrors).length === 0)
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const currentCompany = formData.currentCompany;
    const hiringLocations = formData.hiringLocations;
    const linkedinUrl = formData.linkedInUrl;
    const phoneNumber = formData.phoneNumber;
    const designation = formData.designation;
    const referralCode = formData.referralCode;

    const data = {
      currentCompany,
      hiringLocations,
      linkedinUrl,
      phoneNumber,
      designation,
    };

    updateUserDetails(token, data, referralCode);
  };

  const handleSubmitRefferalCode = () => {
    const data = formData.referralCode;
    if (data === "") {
      message.error("Fill out the Refferal code!!");
      return;
    }
    dispatch(setformSubmitLoader(true));
    submitCouponCode(token, data);
  };

  const handleLocationChange = (selectedLocations) => {
    setFormData((prev) => ({ ...prev, hiringLocations: selectedLocations }));
    if (selectedLocations.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        hiringLocations: "At least one location is required",
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.hiringLocations;
        return newErrors;
      });
    }
  };

  const renderTextField = ({ label, name, required, select, options }) => (
    <div style={{ margin: "1rem 0" }}>
      <TextField
        fullWidth
        key={name}
        margin="normal"
        label={label}
        name={name}
        value={formData[name]}
        onChange={handleInputChange}
        error={!!errors[name]}
        required={required}
        select={select}
        sx={{ backgroundColor: "#f8e9d5", padding: "0", margin: "0" }}
      >
        {select &&
          options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
      </TextField>
      <Typography
        style={{ fontSize: "12px", paddingLeft: "1rem" }}
        color="error"
      >
        {errors[name] || ""}
      </Typography>
    </div>
  );

  const renderSelectField = () => (
    <>
      <FormControl
        fullWidth
        margin="normal"
        error={!!errors.hiringLocations}
        sx={{ mt: "0" }}
      >
        <InputLabel>Locations You are Hiring For</InputLabel>
        <Select
          multiple
          value={formData.hiringLocations}
          onChange={(e) => handleLocationChange(e.target.value)}
          input={<OutlinedInput label="Locations You are Hiring For" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  sx={{ backgroundColor: "#fff",border:"1px solid #f99c38", borderRadius: "16px" }}
                />
              ))}
            </Box>
          )}
          sx={{ backgroundColor: "#f8e9d5" }}
        >
          {locationOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {errors.hiringLocations && (
          <Typography
            style={{ fontSize: "12px", paddingLeft: "1rem" }}
            color="error"
          >
            {errors.hiringLocations}
          </Typography>
        )}
      </FormControl>
    </>
  );

  return (
    <React.Fragment>
      {loadingDetails ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            minHeight: "100vh",
            background:"transparent"
          }}
        >
          <Spin />
        </div>
      ) : showFullForm ? (
        <>
          <BootstrapDialog onClose={handleClose} open={showModal}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
              Wait List Form
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Typography variant="body1" sx={{ mb: 2 }}>
                We'll verify the below details & add you to the user list very
                soon, appreciate your love & patience :)
              </Typography>

              {fields.map(renderTextField)}

              {renderSelectField()}

              {/* Referral Code */}
              <TextField
                fullWidth
                margin="normal"
                label="Referral Code (optional)"
                name="referralCode"
                value={formData.referralCode}
                onChange={handleInputChange}
                error={!!errors.referralCode}
                sx={{ backgroundColor: "#f8e9d5" }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}
                style={{
                  backgroundColor: "#f99c38",
                  border: "1px solid #000",
                  color: "#000",
                  boxShadow: "none",
                }}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </>
      ) : (
        <>
          <BootstrapDialog onClose={handleCloseRefferalForm} open={showModal}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
              Do you have a Refferal Code??
              <IconButton
                aria-label="close"
                onClick={handleCloseRefferalForm}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Typography variant="body1" sx={{ mb: 2 }}>
                We already have your request for access. If you have a referral
                code, please paste it here.
              </Typography>

              {/* Referral Code */}
              <TextField
                fullWidth
                margin="normal"
                label="Referral Code (optional)"
                name="referralCode"
                value={formData.referralCode}
                onChange={handleInputChange}
                error={!!errors.referralCode}
                sx={{ backgroundColor: "#f8e9d5" }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleCloseRefferalForm}
                disabled={loading}
                style={{
                  backgroundColor: "#f8e9d5",
                  color: "#000",
                  fontWeight: "500",
                  boxShadow: "none",
                  border: "1px solid #000",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmitRefferalCode}
                disabled={loading}
                style={{
                  backgroundColor: "#f99c38",
                  border: "1px solid #000",
                  color: "#000",
                  boxShadow: "none",
                }}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </>
      )}
    </React.Fragment>
  );
}