import React from 'react'
import styles from "./Footer.module.css"
import { useNavigate } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
  
  const navigate = useNavigate();

  return (
    <div className={styles.footerContainer}
    >
      <div className={styles.footerSubContainer}>
        <div className={styles.tabuddyLogo}>
          <img src="./img/tabuddyLogo.svg" alt="" />
        </div>

        <div className={styles.footerOptions}>
          <div className={styles.tabuddyHeading}>
            TaBuddy
          </div>
          <div className={styles.footerOption} onClick={() => navigate("/")}>Home</div>
          {/* <div className={styles.footerOption} onClick={() => navigate("/check-bias")}>Check Bias</div> */}
          <div className={styles.footerOption} onClick={() => navigate("/live-sourcing")}>Live Sourcing </div>
          {/* <div className={styles.footerOption} onClick={() => navigate("/features")}>Features</div> */}
          {/* <div className={styles.footerOption} onClick={() => navigate("/pricing")}>Pricing</div> */}
        </div>

        <div className={styles.footerOptions}>
          <div className={styles.tabuddyHeading}>
            I want to
          </div>
          <div className={styles.footerOption}
          onClick={()=>window.open("https://wa.me/917996496999", "_blank")}
          >Contact Us</div>
          <div className={styles.footerOption}>Know About Us</div>
          <div className={styles.footerOption} onClick={()=>navigate("/privacy")}>Privacy Policy</div>
        </div>

        <div className={styles.footerSocials}>
        <LinkedInIcon style={{fontSize:"35px", cursor:"pointer"}} onClick={()=> window.open("https://www.linkedin.com/company/recruitingmonk/", "_blank")}/>
        <FacebookIcon style={{fontSize:"35px", cursor:"pointer"}} onClick={()=> window.open("https://www.facebook.com/recruitingmonk/", "_blank")}/>
        </div>
      </div>
    </div>
  )
}

export default Footer