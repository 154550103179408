import React, { useEffect } from 'react'
import Index from '../components/NewDesign/LiveSourcing/Index'
import ReactGA from "react-ga"

const LiveSourcing = () => {

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname)
  },[])

  return (
    <div><Index /></div>
  )
}

export default LiveSourcing