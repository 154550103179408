import React from "react";
import styles from "./Offering.module.css";
import Anim1 from "../../../Animations/OfferingAnim/OfferingAnim1/Anim";
import Anim2 from "../../../Animations/OfferingAnim/OfferingAnim2/Anim2";
import Anim3 from "../../../Animations/OfferingAnim/OfferingAnim3/Anim3";
import { Router, useNavigate } from "react-router-dom";

const Offering = () => {

  const navigate = useNavigate();

  const points = [
    {
      id: 1,
      minHeading: "SOURCING ASSISTANT",
      heading: "Streamline Your Talent Search",
      subHeading:
        "Simplify how you find top candidates with our Sourcing Agent.",
      data: [
        "AI-Driven Live Sourcing for Top Talent",
        "Top Profiles are Curated and Shortlisted",
        "Adopt a BIAS free Sourcing",
      ],
    },

    {
      id: 2,
      minHeading: "SCREENING",
      heading: "EFFICIENTLY REVIEW PROFILES",
      subHeading:
        "Review profiles in a more methodological structured way",
      data: [
        "Automate Resume Screening and Shortlisting",
        "Create Your Own Resume Ranking System",
        "Prioritise your data buckets based on Diversity, Colleges, Past Companies & Type of Company"
      ],
    },

    {
      id: 3,
      minHeading: "ENGAGEMENT ASSISTANT",
      heading: "Connect with Candidates Effectively",
      subHeading:
        "Enhance your candidate engagement with innovative communication tools.",
      data: [
        "Automate your mail campaigns",
        "Seamless Multi-Channel Candidate Management",
      ],
    },
  ];

  const plans = [
    {
      pricing: "Free",
      heading: "Live Sourcing",
      options: [
        "A weekly, curated list of candidates for top tech roles",
        "Pick Candidates at Zero Cost",
      ],
      button: "Start Sourcing!",
    },
    // {
    //   pricing: "Freemium",
    //   heading: "Hiring Drive",
    //   options: [
    //     "Supercharge Your Sourcing with Targeted Campaigns",
    //     "Access advanced filters: experience filter, ranking algorithm and much more",
    //   ],
    //   button: "Learn More",
    // },
    {
      pricing: "Enterprise",
      heading: "TABuddy Suite",
      options: [
        "Integrate your job boards and LinkedIn sourcing into one streamlined platform",
        "Outsource your entire sourcing to TA Buddy’s Recruiting Suite",
      ],
      button: "Book Demo",
    },
  ];

  const handlePlanClick = (value) => {
    if(value === 0){
      navigate("/live-sourcing");
    }else if (value === 1){
     window.open("https://wa.me/917996496999", "_blank")
    }
  }

  return (
    <div className={styles.offeringContainer}>
      {points.map((point, key) => (
        <div className={styles.offeringSubContainer}>
          <div className={styles.offeringContentContainer} key={key}>
            <span
              className={`${styles.offeringMinHeading} ${
                key === 0
                  ? styles.color1
                  : key === 1
                  ? styles.color2
                  : styles.color3
              }`}
            >
              {point.minHeading}
            </span>
            <h2 className={styles.offeringContentHeading}>{point.heading}</h2>
            <p className={styles.offeringContentSubHeading}>
              {point.subHeading}
            </p>

            <div className={styles.offeringPoints}>
              {point.data.map((point, index) => (
                <div className={styles.offeringPoint} key={index}>
                  <img
                    src={`${
                      key === 0
                        ? `./img/point.svg`
                        : key === 1
                        ? "./img/point2.svg"
                        : "img/point3.svg"
                    }`}
                    alt=""
                  />
                  <span className={styles.offeringPointCOntent}>{point}</span>
                </div>
              ))}
            </div>
          </div>
          <div
            className={`${styles.offeringImage} ${
              key === 0
                ? styles.bgColor1
                : key === 1
                ? styles.bgColor2
                : styles.bgColor3
            }`}
          >
            {
              key == 0 ? <Anim1 /> : key === 1 ? <Anim2 /> : <Anim3 />
            }
            
          </div>
        </div>
      ))}

      <div className={styles.planOptionsContainer}>
        <div className={styles.planHeading}>Find Your Perfect Plan</div>

        <div className={styles.planCardsContainer}>
          {plans.map((plan, index) => (
            <div className={styles.planCard} key={index}>
              <div className={styles.cardContent}>
                <div className={styles.headingContainer}>
                  <span>{plan.pricing}</span>
                  <div className={styles.heading}>{plan.heading}</div>
                </div>

                <div className={styles.planCardPointsContainer}>
                  {plan.options.map((option, index) => (
                    <div className={styles.planPoints} key={index}>
                      <div className={styles.marker}></div>
                      <div className={styles.point}>{option}</div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.buttonContainer}>
                <button
                onClick={() => handlePlanClick(index)}>{plan.button}</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Offering;
