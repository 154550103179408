import React from "react";
import { Button } from "antd";
import styles from "./monkbot.module.css";
import JobCard from "./JobCard";
import { PlusOutlined } from "@ant-design/icons";

const JobsChats = () => {
  return (
    <>
      <div className={styles.jobsContainer}>
        <Button
          type="primary"
          size="large"
          style={{
            minWidth: "80%",
            height: "35px",
            fontSize: "14px",
            padding: "0.5rem 2rem",
            background: "#0778BD",
            marginBottom: "1rem",
          }}
        >
          <PlusOutlined /> Create Job
        </Button>
        <JobCard />
      </div>
    </>
  );
};

export default JobsChats;
