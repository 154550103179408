import React, { useEffect, useState } from "react";
import styles from "./Animaton1.module.css";

const BooleanAnimationPage = ({setShow}) => {
  const [displayedText, setDisplayedText] = useState("");
  const [isTyping, setIsTyping] = useState(true);

  const text = `(JavaScript OR JS) AND (React OR React.js) AND (Node.js OR Node) AND (AWS OR Amazon Web Services)`;

  useEffect(() => {
    if (typeof text !== "string") {
      return;
    }

    let currentIndex = -1;
    const interval = setInterval(() => {
      if (isTyping) {
        if (currentIndex >= text.length - 1) {
          setIsTyping(false);
          clearInterval(interval);
          return;
        }

        currentIndex++;
        setDisplayedText(text.slice(0, currentIndex + 1));
      } else {
        if (currentIndex < 0) {
          setIsTyping(true);
          currentIndex = -1; // Reset for the next cycle
        }
      }
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, [text, isTyping]);

  useEffect(()=> {
    setTimeout(()=> setShow(2), 6000)
  },[])

  return (
    <div className={styles.booleanAnimationContainer}>
      <div className={styles.booleanAnimationHeading}>
        TABuddy, is crafting the best Boolean <br /> search for you
      </div>

      <span className={styles.animatedText}>{displayedText}</span>
    </div>
  );
};

export default BooleanAnimationPage;
