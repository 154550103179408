import React from "react";
import styles from "../Animaton1.module.css";

const RankFilters = ({ jobFilter }) => {
  return (
    <div className={styles.rankFilterContainer}>
      <button
        className={`${styles.jobCandidatesFilterButtonAllRank} ${
          jobFilter === "All" && styles.jobCandidatesFilterButtonAllSelectedRank
        }`}
      >
        <span className={styles.jobCandidatesFilterValue}>108 </span>
        <span className={styles.jobCandidatesFilterName}>All</span>
      </button>

      <button
        className={`${styles.jobCandidatesFilterButtonAllRank} ${
          jobFilter === "R1" && styles.jobCandidatesFilterButtonAllSelectedRank
        }`}
      >
        <span className={styles.jobCandidatesFilterValue}>20 </span>

        <span className={styles.jobCandidatesFilterName}>Rank 1</span>
      </button>

      <button
        className={`${styles.jobCandidatesFilterButtonAllRank} ${
          jobFilter === "R2" && styles.jobCandidatesFilterButtonAllSelectedRank
        }`}
      >
        <span className={styles.jobCandidatesFilterValue}>26 </span>

        <span className={styles.jobCandidatesFilterName}>Rank 2</span>
      </button>

      <button
        className={`${styles.jobCandidatesFilterButtonAllRank} ${
          jobFilter === "R2" && styles.jobCandidatesFilterButtonAllSelectedRank
        }`}
      >
        <span className={styles.jobCandidatesFilterValue}>30 </span>

        <span className={styles.jobCandidatesFilterName}>Rank 3</span>
      </button>

      <button
        className={`${styles.jobCandidatesFilterButtonAllRank} ${
          jobFilter === "R2" && styles.jobCandidatesFilterButtonAllSelectedRank
        }`}
      >
        <span className={styles.jobCandidatesFilterValue}>15 </span>
        <span className={styles.jobCandidatesFilterName}>Rank 4</span>
      </button>

      <button
        className={`${styles.jobCandidatesFilterButtonAllRank} ${
          jobFilter === "R2" && styles.jobCandidatesFilterButtonAllSelectedRank
        }`}
      >
        <span className={styles.jobCandidatesFilterValue}>11 </span>

        <span className={styles.jobCandidatesFilterName}>Rank 5</span>
      </button>

      <button
        className={`${styles.jobCandidatesFilterButtonAllRank} ${
          jobFilter === "R2" && styles.jobCandidatesFilterButtonAllSelectedRank
        }`}
      >
        <span className={styles.jobCandidatesFilterValue}>11 </span>

        <span className={styles.jobCandidatesFilterName}>Unranked</span>
      </button>
    </div>
  );
};

export default RankFilters;
