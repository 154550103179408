import React from "react";
import styles from "./Footer.module.css";
import { Button } from "@mui/material";

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerMainContainer}>
        <div className={styles.footerHeading}>
          Want to improve your sourcing score?
        </div>
        <Button
          onClick={() =>
            window.open(
              "https://learning.recruitingmonk.com/mini-diploma",
              "_blank"
            )
          }
          variant="contained"
          sx={{ background: "#0778BD", height: "35px", width: "100%" }}
        >
          Join Mini Diploma
        </Button>
      </div>
    </div>
  );
};

export default Footer;
