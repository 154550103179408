import React from "react";
import Navbar from "../sharedComponents/NavbarComponent/Navbar.jsx";
import Banner from "./Banner/Banner";
import Info from "./Info/Info";
import MoreInfo from "./MoreInfo/MoreInfo";
import Offering from "./Offerings/Offering";
import Help from "./Help/Help.jsx";
import OptionsInfo from "./OptionsInfo/OptionsInfo.jsx";
import About from "./About/About.jsx";
import Testimonials from "./Testimonials/Testimonials.jsx";
import Footer from "../sharedComponents/Footer/Footer.jsx";
import JoinTaBuddy from "./JoinTaBuddy/JoinTaBuddy.jsx";

const Index = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `
      linear-gradient(to top, #ffffff 2%, rgba(255, 255, 255, 0) 20%),
      linear-gradient(to bottom, #ffffff 2%, rgba(255, 255, 255, 0) 15%),
      url("./img/bg3.jpg")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Navbar />
        <Banner />
        <Info />
      </div>

      <MoreInfo />
      <Offering />
      <Help />
      <OptionsInfo />
      <About />
      <Testimonials />
      <JoinTaBuddy />
      <Footer />
    </>
  );
};

export default Index;
