import React, { useState } from "react";
import styles from "./monkbot.module.css";
import { Tooltip, Typography } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import BooleanAnimationPage from "./BooleanAnimationPage";
import SearchDone from "./SearchDone";
import CandidatesJobFilters from "./CandidatesJobFilters";
import CandidateCardLinkedin from "./CandidateCardLinkedin";

const { Title } = Typography;

const JobCandidiateSection = ({ setShowMessage, show, setShow, showMessage }) => {
  const jobName = "Software Engineer"; // Static Job Name

  return (
    <div className={styles.jobCandidiateSectionContainer} style={{opacity: `${showMessage ? "0.5" : "1" }`
    }}>
      <div>
        <div className={styles.jobheadingContainer}>
          <div className={styles.jobheadingSubContainer}>
            <div>
              <Title level={4} style={{ margin: "0" }}>
                {jobName}
              </Title>
              <p
                style={{
                  color: `${show == 3 ? "#0077bd" : "#fff"}`,
                  fontSize: "12px",
                  marginTop: "4px",
                }}
              >
                2 unique searches done, 2500 profiles spotted, 1000 profiles
                reviewed
              </p>
            </div>
          </div>

          <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
            <button
              className={styles.candidateMessagebutton}
              style={{
                fontWeight: "500",
                padding: "0.5rem 0.5rem",
                fontSize: "12px",
                background: "#0778BD",
                color: "#fff",
              }}
            >
              <PlusOutlined /> <span> Create Campaign</span>
            </button>
          </div>
        </div>
      </div>

      <>
        {show === 1 ? (
          <BooleanAnimationPage setShow={setShow} />
        ) : show === 2 ? (
          <SearchDone setShow={setShow} />
        ) : (
          <>
            <CandidatesJobFilters />
            <div className={styles.jobCandidatesSection}>
              <CandidateCardLinkedin setShowMessage={setShowMessage} setShow={setShow} />
            </div>
          </>
        )} 
      </>
    </div>
  );
};

export default JobCandidiateSection;
