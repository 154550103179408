import React from "react";
import styles from "./Animation3.module.css";
import { Button, Radio, Typography } from "antd";
import {
  LoadingOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";

const SideBarEmail = ({ selected }) => {
  const truncateString = (str) => {
    if (str.length <= 175) {
      return str;
    } else {
      return str.slice(0, 175) + "...";
    }
  };

  const mails = [
    {
      subject:
        "Exciting Opportunity: Join Our Team as a Python Developer at RecruitingMonk!",
      body: `Dear [Candidate's Name],

I hope this email finds you well. I am reaching out to you on behalf of RecruitingMonk with an exciting opportunity that I believe matches your skillset and experience perfectly.

We are currently looking for a talented and motivated Software Developer to join our dynamic team. At RecruitingMonk, we pride ourselves on creating innovative solutions that revolutionize the recruitment industry, and we are seeking a skilled developer who shares our passion for innovation and excellence.

As a Software Developer at RecruitingMonk, you will have the opportunity to work on cutting-edge projects, collaborate with a talented team of professionals, and contribute to the development of groundbreaking recruitment technology.

Key responsibilities of the role include:

Developing and maintaining software applications and systems
Participating in all phases of the software development lifecycle
Collaborating with cross-functional teams to design and implement solutions
Troubleshooting and debugging issues to ensure optimal performance
The ideal candidate will have:

Bachelor's degree in Computer Science, Engineering, or a related field
Proven experience in software development, with expertise in [specific technologies or programming languages relevant to the position]
Strong problem-solving skills and attention to detail
Excellent communication and teamwork abilities
This is an excellent opportunity for a driven and talented individual to take their career to the next level and make a meaningful impact in the recruitment technology space.

If you are interested in joining our team and contributing to our mission of revolutionizing the recruitment industry, please reply to this email with your updated resume attached. We would love to further discuss how your skills and experience align with the role.

Thank you for considering this opportunity, and I look forward to hearing from you soon.

Best regards,

[Your Name]

[Your Position]

RecruitingMonk`,
    },
    {
      subject: "Let's Connect! Python Developer Opportunity at RecruitingMonk",
      body: `Hi [Candidate's Name],
I hope you're doing well! I came across your profile and was impressed by your background in Python development. We’re currently looking to add skilled developers like you to our team at [Your Company Name], and I believe you'd be a great fit for our ongoing projects.`,
    },
  ];

  return (
    <div className={styles.sidebarContainer}>
      <div
        style={{
          minWidth: "100%",
          padding: "0.15rem 0.5rem",
          textAlign: "left",
        }}
      >
        <Typography.Title level={5} style={{ margin: "0", fontSize: "13px" }}>
          Templates
        </Typography.Title>
      </div>

      <div className={styles.buttonContainerSidebar}>
        <Button
          style={{
            fontSize: "10px",
            padding: "1px 2px",
            height: "25px",
            width: "50%",
            color: "#0077bd",
            borderRadius: "0",
            border: "1px solid #0077bd",
          }}
        >
          Your Templates
        </Button>
        <Button
          style={{
            fontSize: "10px",
            padding: "2px",
            height: "25px",
            width: "50%",
            borderRadius: "0",
          }}
        >
          Monk Templates
        </Button>
      </div>

      <div className={styles.templateContainer}>
        {mails.map((mail, index) => (
          <div className={styles.template} key={index}>
            <div className={styles.templateTitle}>
              <div style={{ width: "5%" }}>
                <Radio
                  checked={selected && index === 0}
                  style={{ color: "#0077bd" }}
                />
              </div>
              <div style={{ width: "90%", paddingLeft: "10px" }}>
                Software Engineer- Bengaluru, Karnataka
              </div>
            </div>

            <div className={styles.templateBoxEmail}>
              <div className={styles.emailTemplateSubject}>
                <span>Subject:</span>
                <span>{truncateString(mail.subject)}</span>
              </div>

              <div className={styles.emailTemplateMessage}>
                <p>{truncateString(mail.body)}</p>
                <div className={styles.fullTemplateButton}>
                  <CaretDownOutlined />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.buttonContainerSideBarBottom}>
        <Button
          style={{
            fontSize: "10px",
            padding: "1px 2px",
            height: "30px",
            width: "50%",
            color: "#0077bd",
            // borderRadius: "0",
            border: "1px solid #0077bd",
          }}
        >
          Close
        </Button>
        <Button
          style={{
            fontSize: "10px",
            padding: "2px",
            height: "30px",
            width: "50%",
            // borderRadius: "0",
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default SideBarEmail;
