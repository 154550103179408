import React, { useEffect } from 'react'
import Home from './Home'
import WaitListForm from '../components/NewDesign/sharedComponents/Waitlist_form/WaitListForm'
import ReactGA from "react-ga"


const WaitList = () => {

  useEffect(()=>{
    ReactGA.pageview(window.location.pathname)
  },[])

  return (
    <>
    <WaitListForm />
    <div style={{filter: "blur(5px)", overflow:"hidden" }}>
        <Home />
    </div>
    </>
  )
}

export default WaitList