import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "./Navbar.module.css";

export default function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleRouting = (text) => {
    if (text === "Home") {
      navigate("/home");
    } else if (text === "bias") {
      navigate("/check-bias");
    } else if (text === "Sourcing Drive") {
      navigate("sourcing-drive");
    } else {
      navigate("/pricing");
    }
  };

  const NavbarMobileView = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      style={{
        height: "100%",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <img src="./img/tabuddyLogo.svg" alt="" style={{}} />
        <List>
          {["Home", "Bias", "Sourcing Drive", "Pricing"].map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              onClick={() => handleRouting(text)}
            >
              <ListItemButton>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>

      <div className={styles.loginButtonContainer}>
        <button className={styles.loginButton} style={{ width: "100%" }}>
          Login
        </button>
      </div>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)} style={{ padding: "0" }}>
        <MenuIcon style={{ color: "#000", fontSize: "30px" }} />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {NavbarMobileView}
      </Drawer>
    </div>
  );
}
