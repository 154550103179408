import React, { useEffect } from "react";
import styles from "./Animaton1.module.css";

const SearchDone = ({setShow}) => {
  useEffect(() => {
    setTimeout(() => setShow(3), 2500);
  }, []);

  return (
    <div
      className={styles.booleanAnimationContainer}
      style={{ gap: "0.05rem" }}
    >
      <img src="./img/animation/Completed.gif" alt="" width={"15%"} />
      <div className={styles.booleanAnimationHeading}>
        Search 1 Executed
        <p style={{ fontSize: "12px" }}>
          TABuddy spotted 1000 candidates, curating <br /> the top matches for
          you.
        </p>
      </div>
    </div>
  );
};

export default SearchDone;
