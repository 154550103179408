import React from 'react'
import Banner from '../components/NewDesign/ScoringGaps/Banner/Banner'
import Search from '../components/NewDesign/ScoringGaps/Search/Search'
import Footer from '../components/NewDesign/ScoringGaps/footer/Footer'

const ScoringGaps = () => {
  return (
    <div>
        <Banner />
        <Search />
        <Footer />
    </div>
  )
}

export default ScoringGaps