import axios from "axios";
import { useDispatch } from "react-redux";
import {
  chatsRequest,
  createChatFailure,
  createChatSuccess,
  deleteChatFailure,
  deleteChatSuccess,
  getBooleanSuccess,
  getChatHistoriesFailure,
  getChatHistoriesSuccess,
  getChatsFailure,
  getChatsSuccess,
  getCompanyCluster,
  updateChatTitleFailure,
  updateChatTitleSuccess,
  updateUserRatings,

  // create company cluster
  createCompanyCluster,
  createCompanyCluterSuccess,
  createCompanyCluterFailure,

  // update company cluster
  updateCompanyCluster,
  updateCompanyClusterSuccess,
  updateCompanyClusterFailure,

  // update company cluster title
  updateCompanyClusterTitle,
  updateCompanyClusterTitleSuccess,
  updateCompanyClusterTitleFailure,

  // delete company cluster
  deleteCompanyCluster,
  deleteCompanyClusterSuccess,
  deleteCompanyClusterFailure,

  // Convert to Xray Company Cluster
  convertToXrayCompanyCluster,
  convertToXrayCompanyClusterSuccess,
  convertToXrayCompanyClusterFailure,
  getFirstChatSuccess,
} from "../features/chats/chatsSlice";
import { useNavigate } from "react-router-dom";
import {
  getUserFailure,
  getUserSuccess,
  logoutSuccess,
} from "../features/auth/authSlice";

function useChatData() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const TOKEN = localStorage.getItem("token")
    ? "jwt " + localStorage.getItem("token").toString()
    : "none";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getChats = () => {
    dispatch(chatsRequest());
    axios({
      method: "get",
      baseURL: BASE_URL,
      url: "/user/chats",
      params: {
        pageNumber: 1,
        pageSize: 10,
      },
      headers: {
        Authorization: TOKEN,
      },
    })
      .then((res) => {
        dispatch(getChatsSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(getChatsFailure(error.response.data));
      });
  };

  const createChat = (body) => {
    dispatch(chatsRequest());
    axios({
      method: "post",
      baseURL: BASE_URL,
      url: "/user/chats",
      headers: {
        Authorization: TOKEN,
      },
      data: body,
    })
      .then((res) => {
        dispatch(createChatSuccess(res.data.data));
        navigate(
          `/app/chats/${res.data.data.category}/${res.data.data["_id"]}`
        );
        getChats();
      })
      .catch((error) => {
        const errorMsg = "Some Error";
        dispatch(createChatFailure(errorMsg));
      });
  };

  const updateChatTitle = (body, id) => {
    dispatch(chatsRequest());
    axios({
      method: "put",
      baseURL: BASE_URL,
      url: `/user/chats/${id}`,
      headers: {
        Authorization: TOKEN,
      },
      data: body,
    })
      .then((res) => {
        dispatch(updateChatTitleSuccess(res.data));
        getChats();
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateChatTitleFailure(error));
      });
  };

  const deleteChat = (id) => {
    dispatch(chatsRequest());
    axios({
      method: "delete",
      baseURL: BASE_URL,
      url: `/user/chats/${id}`,
      headers: {
        Authorization: TOKEN,
      },
    })
      .then((res) => {
        dispatch(deleteChatSuccess(res.data));
        getChats();
      })
      .catch((error) => {
        dispatch(deleteChatFailure(error));
      });
  };

  const getChatHistory = (id, category) => {
    // dispatch(chatsRequest());
    axios({
      method: "get",
      baseURL: BASE_URL,
      url: `/user/chats/${id}/histories`,
      headers: {
        Authorization: TOKEN,
      },
      params: {
        pageNumber: 1,
        pageSize: 10,
        category: category,
      },
    })
      .then((res) => {
        dispatch(getChatHistoriesSuccess(res.data.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getChatHistoriesFailure(error));
      });
  };

  const getFirstChat = (id, category) => {
    // dispatch(chatsRequest());
    axios({
      method: "get",
      baseURL: BASE_URL,
      url: `/user/chats/${id}/histories`,
      headers: {
        Authorization: TOKEN,
      },
      params: {
        pageNumber: 1,
        pageSize: 30,
        category: category,
      },
    })
      .then((res) => {
        dispatch(getFirstChatSuccess(res.data.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getChatHistoriesFailure(error));
      });
  };

  const updateChatHistory = async (id, body, category) => {
    return await axios({
      method: "put",
      baseURL: BASE_URL,
      url: `/user/chats/${id}/histories`,
      headers: {
        Authorization: TOKEN,
      },
      data: body,
    })
      .then((res) => {
        if (res.data.status) {
          getChatHistory(id, category);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(getChatHistoriesFailure(error));
      });
  };

  const createBoolean = async (id, body, formattedDate, category) => {
    return await axios({
      method: "post",
      baseURL: BASE_URL,
      url: `/user/chats/${id}/boolean/generate-booleans`,
      headers: {
        Authorization: TOKEN,
      },
      data: body,
    })
      .then((res) => {
        const data = res.data.data;
        dispatch(getBooleanSuccess(res.data.data));

        if (data.excludeArray?.length === 0) {
          updateChatHistory(
            id,
            {
              message: data.includedSkills,
              sender: "BOOLEANBOTGENERATED",
              sentAt: formattedDate,
            },
            category
          );
        }
        if (data.excludeArray?.length !== 0) {
          updateChatHistory(
            id,
            {
              message: data.includedSkills,
              sender: "BOOLEANBOTINCLUDED",
              sentAt: formattedDate,
            },
            category
          );
        }

        if (data.excludeArray?.length !== 0 && data.includedSkills === "") {
          updateChatHistory(
            id,
            {
              message: `The Skills you enterd are not mandatory for this role`,
              sender: "BOOLEANBOTINCLUDED",
              sentAt: formattedDate,
            },
            category
          );
        }
      })

      .catch((error) => {
        console.log(error);
        dispatch(getChatHistoriesFailure(error));
      });
  };

  const getAssessmentQuestions = async (role) => {
    return await axios({
      method: "get",
      baseURL: BASE_URL,
      url: `/user/assessment-questions`,
      headers: {
        Authorization: TOKEN,
      },
      params: {
        role: role,
      },
    })
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        console.log(error);
        dispatch(getChatHistoriesFailure(error));
      });
  };

  const getRatings = async () => {
    return await axios({
      method: "get",
      baseURL: BASE_URL,
      url: `/user/ratings`,
      headers: {
        Authorization: TOKEN,
      },
    })
      .then((res) => {
        if (res.data.data) {
          dispatch(updateUserRatings(res.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(getChatHistoriesFailure(error));
      });
  };

  const submitRatings = async (body) => {
    return await axios({
      method: "post",
      baseURL: BASE_URL,
      url: `/user/ratings`,
      headers: {
        Authorization: TOKEN,
      },
      data: body,
    })
      .then((res) => {
        console.log(body);
        if (res.data.data) {
          return res.data.data;
        }
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  };

  const getComanyClusters = async (type) => {
    return await axios({
      method: "get",
      baseURL: BASE_URL,
      url: `/user/company-clusters`,
      headers: {
        Authorization: TOKEN,
      },
      params: {
        type: type,
        // search: "top",
        pageNumber: 1,
        pageSize: 10,
      },
    })
      .then((res) => {
        dispatch(getCompanyCluster(res.data.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getChatHistoriesFailure(error));
      });
  };

  const updateComanyClusters = async (body, id) => {
    dispatch(updateCompanyCluster());
    return await axios({
      method: "put",
      baseURL: BASE_URL,
      url: `/user/company-clusters/${id}`,
      headers: {
        Authorization: TOKEN,
      },
      data: body,
    })
      .then((res) => {
        dispatch(updateCompanyClusterSuccess(res?.data?.data?.message));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateCompanyClusterFailure(error));
      });
  };

  const updateComanyClusterTitle = async (body, id) => {
    dispatch(updateCompanyClusterTitle());
    return await axios({
      method: "put",
      baseURL: BASE_URL,
      url: `/user/company-clusters/${id}/title`,
      headers: {
        Authorization: TOKEN,
      },
      data: body,
    })
      .then((res) => {
        dispatch(updateCompanyClusterTitleSuccess(res?.data?.data?.message));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateCompanyClusterTitleFailure(error));
      });
  };

  const deleteComanyCluster = async (id) => {
    dispatch(deleteCompanyCluster());
    return await axios({
      method: "delete",
      baseURL: BASE_URL,
      url: `/user/company-clusters/${id}`,
      headers: {
        Authorization: TOKEN,
      },
    })
      .then((res) => {
        dispatch(deleteCompanyClusterSuccess(res?.data?.data?.message));
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteCompanyClusterFailure(error));
      });
  };

  const createCompanyClusters = async (body) => {
    dispatch(createCompanyCluster());
    return await axios({
      method: "post",
      baseURL: BASE_URL,
      url: `user/company-clusters`,
      headers: {
        Authorization: TOKEN,
      },
      data: body,
    })
      .then((res) => {
        dispatch(createCompanyCluterSuccess(res.data.data.message));
      })
      .catch((error) => {
        console.log(error);
        dispatch(createCompanyCluterFailure(error));
      });
  };

  const convertToXrayComanyCluster = async (id) => {
    dispatch(convertToXrayCompanyCluster());
    return await axios({
      method: "put",
      baseURL: BASE_URL,
      url: `/user/company-clusters/${id}/convert-to-xray`,
      headers: {
        Authorization: TOKEN,
      },
    })
      .then((res) => {
        dispatch(convertToXrayCompanyClusterSuccess(res?.data?.data?.message));
      })
      .catch((error) => {
        console.log(error);
        dispatch(convertToXrayCompanyClusterFailure(error));
      });
  };

  const uploadCertificate = async (body) => {
    return await axios({
      method: "post",
      baseURL: BASE_URL,
      url: `/user/files/upload`,
      headers: {
        Authorization: TOKEN,
      },
      data: body,
    })
      .then((res) => {
        const TOKEN = localStorage.getItem("token")
          ? "jwt " + localStorage.getItem("token").toString()
          : "none";
        axios({
          method: "get",
          baseURL: BASE_URL,
          url: "/user/profile-details",
          headers: {
            Authorization: TOKEN,
          },
        }).then((res) => {
          dispatch(getUserSuccess(res.data));
        });
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  };

  return {
    getChats,
    createChat,
    updateChatTitle,
    deleteChat,
    getChatHistory,
    getFirstChat,
    updateChatHistory,
    createBoolean,
    getAssessmentQuestions,
    getRatings,
    submitRatings,
    getComanyClusters,
    updateComanyClusters,
    createCompanyClusters,
    updateComanyClusterTitle,
    deleteComanyCluster,
    convertToXrayComanyCluster,
    uploadCertificate,
  };
}

export default useChatData;
