import CryptoJS from 'crypto-js';
import { jwtDecode } from 'jwt-decode';  // Correct import for the latest version

export function decryptedToken(encryptedData) {
    const encryptionKey = process.env.REACT_APP_DEENCRYPTION;

    try {
        // Clean and decode the parameter
        const cleanData = decodeURIComponent(encryptedData.trim());
        
        const parts = cleanData.split(':');
        if (parts.length !== 2) {
            throw new Error("Invalid encrypted data format. Expected format: 'IV:EncryptedToken'.");
        }

        const [ivBase64, encryptedTokenBase64] = parts;
        // Parse IV and Encrypted Token
        const iv = CryptoJS.enc.Base64.parse(ivBase64);
        const encryptedToken = CryptoJS.enc.Base64.parse(encryptedTokenBase64);

        // Parse Key
        const key = CryptoJS.enc.Hex.parse(encryptionKey);

        // Decrypt
        const decryptedBytes = CryptoJS.AES.decrypt(
            { ciphertext: encryptedToken },
            key,
            { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
        );
        
        // Convert to UTF-8
        const decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);

        if (!decryptedToken) throw new Error("Decryption failed. Empty result.");

        return decryptedToken;
    } catch (error) {
        return "Decryption Error"
    }
}

export function decodeJWT_Token(token){
    try {
        const decoded = jwtDecode(token);
        const userId = decoded.sub || decoded._id;  
        return userId;
      } catch (error) {
        console.error("Invalid token or decoding error", error);
      }
}