import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import { useSelector } from "react-redux";

const NameAvatar = () => {
  const userDetails = useSelector((state) => state.auth2.userDetails);

  return (
    <Avatar
      style={{
        backgroundColor: "#fff",
        color: "#808080",
        border: "1px solid #808080",
        fontSize: "20px",
        fontWeight: "550",
        verticalAlign: "centre",
        cursor: "pointer",
      }}
      size="large"
    >
      {`${userDetails?.firstName?.charAt(0)}${userDetails?.lastName?.charAt(
        0
      )}`}
    </Avatar>
  );
};

export default NameAvatar;
