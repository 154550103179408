import React, { useState } from "react";
import styles from "./Search.module.css";
import { Button, TextField } from "@mui/material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { PieChart } from "react-minimal-pie-chart";

const Search = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const data = [{ title: "One", value: 20, color: "#011632" }];

  return (
    <>
      <div className={styles.searchBoxContainer}>
        <input
          className={styles.search}
          placeholder="Enter Role"
          sx={{ border: "1.5px solid #000" }}
        />
        <Button
          variant="contained"
          sx={{ background: "#0778BD", height: "45px", width: "100px" }}
        >
          Seacrh
        </Button>
      </div>

      <div className={styles.jobDescriptionContainer}>
        <FormControl sx={{ m: 1, minWidth: "60%" }}>
          <InputLabel>Job Description</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select"
            label="Role"
          >
            <MenuItem value="Job Description">Job Description</MenuItem>
          </Select>
        </FormControl>
        <div className={styles.inputBoxContainer}>
          <TextField
            multiline
            minRows={4}
            variant="standard"
            placeholder="Enter your search here"
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                borderBottom: "none",
              },
              "& .MuiInputBase-inputMultiline": {
                padding: 0,
              },
              "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                borderBottom: "none",
              },
              "& .Mui-focused": {
                outline: "none",
              },
            }}
            InputProps={{
              disableUnderline: true, // This will remove the underline entirely
            }}
          />

          <div className={styles.buttonContainer}>
            <Button
              variant="contained"
              sx={{
                background: "#0778BD",
                height: "45px",
                width: "100px",
                border: "1px solid #000",
                width: "100px",
                height: "40px",
                fontWeight: "400",
              }}
            >
              Analyze
            </Button>

            <Button
              variant="contained"
              sx={{
                background: "transparent",
                color: "#000",
                height: "45px",
                width: "100px",
                border: "1px solid #000",
                width: "110px",
                height: "40px",
                fontWeight: "400",
              }}
            >
              Try again
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.reportContainer}>
        <div className={styles.reportSubContainer}>
          <div className={styles.reportHeading}>Your Report</div>

          <div className={styles.reportContentContainers}>
            <div className={styles.reportContentContainer}>
              <div className={styles.reportContentHeading}>Synonyms</div>
              <div className={styles.reportContent}>40%</div>
            </div>

            <div className={styles.reportContentContainer}>
              <div className={styles.reportContentHeading}>Bias</div>
              <div className={styles.reportContent}>40%</div>
            </div>
            <div className={styles.reportContentContainer}>
              <div className={styles.reportContentHeading}>Tech</div>
              <div className={styles.reportContent}>40%</div>
            </div>
            <div className={styles.reportContentContainer}>
              <div className={styles.reportContentHeading}>Boolean Concept</div>
              <div className={styles.reportContent}>40%</div>
            </div>
            <div className={styles.reportContentContainer}>
              <div className={styles.reportContentHeading}>Overall Score</div>
              <div className={styles.reportContent}>
                <PieChart
                  data={data}
                  background="#D9D9D9"
                  animate={true}
                  label={({ dataEntry }) => `${dataEntry.value}%`} // Display title and value
                  labelPosition={0} // Adjusts the position of the label, 50 places it on the outside edge
                  labelStyle={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    fill: "#000", // You can change the label color here
                  }}
                  totalValue={100}
                  radius={50}
                  lineWidth={15}
                  startAngle={0}
                  viewBoxSize={[100, 100]}
                  style={{
                    width: "280px",
                    height: "280px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.advanceReportContainer}>
        <div className={styles.reportSubContainer}>
          {!loggedIn && (
            <div className={styles.loginContainer}>
              <div className={styles.loginText}>
                Want in-detail analysis of your search?
              </div>

              <Button
                variant="contained"
                sx={{ background: "#0778BD", height: "35px", width: "100px" }}
                onClick={() => setLoggedIn(true)}
              >
                Login
              </Button>
            </div>
          )}
          <div className={styles.reportHeading}>Advanced Report</div>

          <div
            className={`${styles.advancedReportContainers} ${
              !loggedIn ? styles.no_select : ""
            }`}
          >
            <div className={styles.advancedReportContainer}>
              <div>Designation Bias</div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut
                aliquam debitis eaque, ut tempora ipsa culpa perspiciatis,
                dignissimos voluptatem
              </div>
            </div>

            <div className={styles.advancedReportContainer}>
              <div>Irrelevant Synonyms</div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut
                aliquam debitis eaque, ut tempora ipsa culpa perspiciatis,
                dignissimos voluptatem
              </div>
            </div>
            <div className={styles.advancedReportContainer}>
              <div>Missing Synonyms </div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut
                aliquam debitis eaque, ut tempora ipsa culpa perspiciatis,
                dignissimos voluptatem
              </div>
            </div>
            <div className={styles.advancedReportContainer}>
              <div>Boolean Concepts </div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut
                aliquam debitis eaque, ut tempora ipsa culpa perspiciatis,
                dignissimos voluptatem
              </div>
            </div>
            <div className={styles.advancedReportContainer}>
              <div>Skill Bias</div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut
                aliquam debitis eaque, ut tempora ipsa culpa perspiciatis,
                dignissimos voluptatem
              </div>
            </div>
            <div className={styles.advancedReportContainer}>
              <div>Missing Data Patterns</div>
              <div>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut
                aliquam debitis eaque, ut tempora ipsa culpa perspiciatis,
                dignissimos voluptatem
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
