import React from "react";
import styles from "./Animation3.module.css";
import { Button, Typography, Input } from "antd";
import {
  PlusOutlined,
  CaretDownOutlined,
  MailOutlined,
} from "@ant-design/icons";

const { Text } = Typography;
const { TextArea } = Input;


const FollowUpEmailMessage = ({
  emailFollowUp,
  setEmailFollowUp,
}) => {
  return (
    <div className={styles.followupContainer}>
      <div className={styles.followUpEmailMessageContainer}>
        <div className={styles.followUpmessageHeading}>
          <p className={styles.followupcount}>
            <span>1</span> Follow Up Message
          </p>
        </div>

        <div className={styles.arrow}>
          <div className={styles.straightline}></div>
          <CaretDownOutlined />
        </div>

        <div className={styles.emailinitialmessagecontainer}>
          <div className={styles.emailContainerHeading}>
            <div className={styles.emailContentRightHeading}>
              <MailOutlined />
              <Text
                style={{ color: "#0778BD", width: "95%", fontWeight: "bold" , fontSize:"10px"}}
              >
                Follow Up #1
              </Text>
            </div>

            <div className={styles.emailContentLeftHeading}>
              <span style={{ fontWeight: "600", fontSize: "9px" }}>
                Scheduled:{" "}
              </span>
              <span style={{ fontWeight: "400", fontSize: "9px" }}>
                24 hours after initial mail
              </span>
            </div>
          </div>

          <div className={styles.emailfollowupLevelsContainer}>
            <TextArea
              autoSize={{ minRows: 5, maxRows: 6 }}
              placeholder="Enter Message here"
              style={{ border: "2px solid #f2f2f2", fontSize: "10px" }}
              bordered={false}
              value={emailFollowUp.body}
              onChange={(e) => setEmailFollowUp(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowUpEmailMessage;
