import React from "react";
import Banner from "./Banner/Banner";
import Navbar from "../sharedComponents/NavbarComponent/Navbar";
import Sourcing from "./SourcingSection/Sourcing";
import Footer from "../sharedComponents/Footer/Footer";
import SubFooter from "./SubFooter/SubFooter";
import WaitListForm from "../sharedComponents/Waitlist_form/WaitListForm";

const Index = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `
            linear-gradient(to bottom, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 1) 100%),
            url("./img/bg2.jpg")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: `center 4rem`, // Adjust vertical offset
          paddingBottom: "10rem",
        }}
      >
        {/* <WaitListForm /> */}
        <Navbar />
        <Banner />
        <Sourcing />
      </div>
      <SubFooter />
      <Footer />
    </>
  );
};

export default Index;
