import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Sourcing.module.css";
import {
  setOpenLoginModal,
  setWaitListFormModal,
} from "../../../../features/auth2/authSlice2";

const LoginToSeeMore = ({ page }) => {
  const dispatch = useDispatch();
  const candidates = useSelector((state) => state.profile.profiles);

  const handleLogin = () => {
    // dispatch(setWaitListFormModal(true))
    dispatch(setOpenLoginModal(true));
  };

  return (
    <div className={styles.blurContainer}>
      <div className={styles.candidatesList}>
        {candidates
          .slice(page === 3 ? 40 : 30, candidates.length)
          .map((candidate, index) => (
            <div
              key={index}
              className={`${styles.blurCandidateCard} ${styles.fadeIn}`}
              style={{
                borderBottom: `${
                  candidates?.length - 1 === index ? "none" : ""
                }`,
              }}
            >
              <div className={styles.profileInfo}>
                <div className={styles.noCandidatePic}></div>
                <div className={styles.candidateInfo}>
                  <div className={styles.candidateName}>{candidate.name}</div>
                  <div className={styles.candidateRole}>
                    {candidate.occupation}
                  </div>
                  <div className={styles.candidateLocation}>
                    {candidate.location}
                  </div>
                </div>
              </div>
              <button className={styles.connectButton}>Connect</button>
            </div>
          ))}
      </div>
      <div className={styles.overlayContainer}>
        <div className={styles.loginMessage}>To avail full access?</div>
        <button className={styles.overlayButton} onClick={handleLogin}>
          Join the waitlist
        </button>
      </div>
    </div>
  );
};

export default LoginToSeeMore;
