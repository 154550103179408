import React from "react";
import styles from "./OptionsInfo.module.css";

const OptionsInfo = () => {
  return (
    <div className={styles.optionsInfoContainer}>
      <div className={styles.optionsInfoSubContainer}>
        <div className={styles.optionsInfoHeading}>
          Tailored for Every Recruiter
        </div>

        <div className={styles.optionsInfoContentContainer}>
          <div className={styles.optionsInfoPointContainer}>
            <div className={styles.optionsPointHeading}>Specialized Roles</div>
            <div className={styles.optionsPointSubheading}>
              Quickly identify candidates with the specific skills and
              experience you need.
            </div>
          </div>

          <div className={styles.optionsInfoPointContainer}>
            <div className={styles.optionsPointHeading}>
              High-Volume Recruiting
            </div>
            <div className={styles.optionsPointSubheading}>
              Efficiently manage large pools of candidates and zero in on the
              best talent.
            </div>
          </div>

          <div className={styles.optionsInfoPointContainer}>
            <div className={styles.optionsPointHeading}>Startup Hiring</div>
            <div className={styles.optionsPointSubheading}>
              Find the perfect fit for your growing team with minimal effort.
            </div>
          </div>
        </div>

        <div className={styles.messageContainer}>
            <div className={styles.messageHeading}>
            Take Your Recruiting to the Next Level
            </div>

            <button
            onClick={()=>window.open("https://wa.me/917996496999", "_blank")}
            >Get Started with TaBuddy</button>
        </div>
      </div>
    </div>
  );
};

export default OptionsInfo;
