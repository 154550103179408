import React, { useState } from "react";
import { Col, Row } from "antd";
import MessageSentPopup from "../MessageSent"
import JobCandidiateSection from "../JobCandidiateSection";
import JobCandidiateSectionAnim2 from "./JobCandidiateSectionAnim2";

const Anim = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [show, setShow] = useState(1);

  return (
      <div
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
          overflow: "hidden",
          borderRadius: "10px 0 10px 0",
        }}
      >
        <Col>
          <Row
            style={{
              maxHeight: "100%",
              minWidth: "100%",
              overflow: "hidden",
            }}
          >
            <Col
              style={{
                width: "100%",
              minWidth: "100%",
                overflow: "scroll",
                background: "#fff",
                overflow: "hidden",
              }}
            >
              <JobCandidiateSectionAnim2
                showMessage={showMessage}
                setShowMessage={setShowMessage}
                show={show}
                setShow={setShow}
              />
            </Col>
          </Row>
        </Col>
      </div>
  );
};

export default Anim;
