import React, { useEffect } from "react";
import { Button, Modal, Typography } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import styles from "./Animation3.module.css";

const { Title, Paragraph } = Typography;

const CampaignCreatedPopUp = () => {
  return (
    <div className={styles.modal}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <div className={styles.booleanSvgBG}>
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ fontSize: "20px" }}
          />
        </div>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "2rem", fontSize: "18px" }}
      >
        <Title level={3} style={{ fontSize: "14px" }}>
          Awesome!
        </Title>
        <Paragraph style={{ fontSize: "12px", marginBottom: "1rem" }}>
          Campaign has been created successfully.
        </Paragraph>
        <Button
          type="primary"
          style={{ backgroundColor: "#0778BD", fontSize: "10px" }}
          size="small"
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default CampaignCreatedPopUp;
