import React, { useState } from "react";
import styles from "./Admin.module.css";
import useAuthData from "../../api/useAuthData";
import { useSelector } from "react-redux";

const AdminLogin = () => {
  const { checkAdmin } = useAuthData();
  
  const adminLoadingButton = useSelector((state)=> state.auth2.adminLoadingButton);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if(email !== "" && password !== ""){
        checkAdmin({email, password});
    }
  };


  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h2 className={styles.title}>Admin Login</h2>

        <div className={styles.inputGroup}>
          <label htmlFor="email" className={styles.label}>
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
            placeholder="Enter your email"
            required
          />
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="password" className={styles.label}>
            Password:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
            placeholder="Enter your password"
            required
          />
        </div>

        <button type="submit" className={styles.button} disabled={adminLoadingButton}>
         {adminLoadingButton ? "Submitting.." :"Login"}
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
