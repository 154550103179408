import React from "react";
import EmailMessage from "./EmailMessage";

const CampaignContainer = () => {
  return (
    <>
      <EmailMessage />
    </>
  );
};

export default CampaignContainer;
