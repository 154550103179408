import React from "react";
import styles from "./Animation3.module.css";
import { CaretDownOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Typography, Input } from "antd";

const { Text } = Typography;
const { TextArea } = Input;

// Load ReactQuill dynamically only on the client side
// const ReactQuill = dynamic(() => import("react-quill"), { ssr: false });
// import "react-quill/dist/quill.snow.css";

const InitialEmailMessage = ({ body, subject, setSubject, setBody }) => {
  const handleBodyChange = (e) => {
    setBody(e);
  };

  return (
    <div className={styles.followUpEmailContainer}>
      <div className={styles.followUpWhatsapp}>
        <p className={styles.followupcount}>Initial Message</p>
      </div>

      <div className={styles.arrow}>
        <div className={styles.straightline}></div>
        <CaretDownOutlined />
      </div>

      <div className={styles.emailinitialmessagecontainer}>
        <div className={styles.emailContainerHeading}>
          <MailOutlined style={{ fontSize: "10px" }} />
          <Text
            style={{
              color: "#0778BD",
              width: "95%",
              fontWeight: "bold",
              fontSize: "10px",
            }}
          >
            Initial Message
          </Text>
        </div>

        <div className={styles.emailFieldsContainer}>
          <TextArea
            autoSize={{ maxRows: 2 }}
            placeholder="Enter Subject here"
            style={{ border: "2px solid #f2f2f2", fontSize: "10px" }}
            bordered={false}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />

          <TextArea
            autoSize={{ minRows: 4 }}
            placeholder="Enter Message here"
            style={{ border: "2px solid #f2f2f2", fontSize: "10px" }}
            bordered={false}
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default InitialEmailMessage;
