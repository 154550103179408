import React from "react";
import styles from "./Banner.module.css";
import Navbar from "../../sharedComponents/NavbarComponent/Navbar.jsx";

const Banner = () => {
  return (
    <div
      className={styles.bannerContainer}
      style={{ backgroundImage: `url(/img/headerImg.png)` }}
    >
      <Navbar />

      <div className={styles.subBannercontainer}>
        <div className={styles.bannerHeading}>
          Check Your Sourcing <br />
          Gaps & Biases
        </div>

        <div className={styles.bannerSubHeading}>
          Uncover and address gaps in your recruitment searches.
        </div>
      </div>
    </div>
  );
};

export default Banner;
