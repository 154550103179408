import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import authReducer from '../features/auth/authSlice';
import chatsReducer from '../features/chats/chatsSlice';
import profileReducer from '../features/profileSlice/profileSlice';
import authReducer2 from '../features/auth2/authSlice2';

// Encryptor for auth and auth2
const authEncryptor = encryptTransform({
  secretKey: '21238y2hjdbhehgy8y182hbwh', // Replace with a secure key
  onError: (error) => console.error('Encryption error:', error),
});

// Persist configurations
const authPersistConfig = {
  key: 'auth',
  storage,
  transforms: [authEncryptor], // Encrypt auth state
};

const auth2PersistConfig = {
  key: 'auth2',
  storage,
  transforms: [authEncryptor], // Encrypt auth2 state
};

const chatsPersistConfig = {
  key: 'chats',
  storage, // No encryption
};

const profilePersistConfig = {
  key: 'profile',
  storage, // No encryption
};

// Wrap reducers with persist configurations
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedAuth2Reducer = persistReducer(auth2PersistConfig, authReducer2);
const persistedChatsReducer = persistReducer(chatsPersistConfig, chatsReducer);
const persistedProfileReducer = persistReducer(profilePersistConfig, profileReducer);

// Configure the store
export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    auth2: persistedAuth2Reducer,
    chats: persistedChatsReducer,
    profile: persistedProfileReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/REGISTER',
          'persist/FLUSH',
          'persist/PAUSE',
          'persist/PURGE',
        ],
      },
    }),
});

// Persistor
export const persistor = persistStore(store);
