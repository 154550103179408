import React from "react";
import { Button, Modal, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleTwoTone  } from "@ant-design/icons";
import styles from "./AddedToWaitlistPopup.module.css"
import { setOpenAddedToWaitlistModal } from "../../../../features/auth2/authSlice2";

const { Title, Paragraph } = Typography;

const ErrorPopupModal = () => {
  const dispatch = useDispatch();
  const open  = useSelector((state)=> state.auth2.addedToWaitListModal);

  const handleClose = () => {
    dispatch(setOpenAddedToWaitlistModal(false));
  }

  return (
    <Modal
      centered
      open={open}
      width={500}
      footer={null} 
      closeIcon={true}
      onCancel={handleClose}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // marginBottom: "0.5rem",
        }}
      >
        <div className={styles.booleanSvgBG}>
        <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ fontSize: "40px" }}
          />
        </div>
      </div>
      <div
        style={{ textAlign: "center", marginBottom: "0.5rem", fontSize: "18px" }}
      >
        <Title level={3}>Successfully added to waitlist!!</Title>
        <Paragraph style={{ fontSize: "16px", marginBottom: "2rem" }}>
        Thanks for applying for full access! We will verify all the details and provide you access very soon.
        </Paragraph>
      </div>
    </Modal>
  );
};

export default ErrorPopupModal;
