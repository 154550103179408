import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";  // Add Firestore import

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCAUK7c-KNMIsYHV9tr9cUFtF7w68s362w",
  authDomain: "ta-coaching.firebaseapp.com",
  databaseURL: "https://ta-coaching.firebaseio.com",
  projectId: "ta-coaching",
  storageBucket: "ta-coaching.appspot.com",
  messagingSenderId: "503126540750",
  appId: "1:503126540750:web:cacc82d93b69435aec2b9f",
  measurementId: "G-Y5875587EJ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore and export it
export const db = getFirestore(app);  // Initialize and export Firestore
