import React, { useEffect, useState } from 'react'
import styles from "./Banner.module.css"
import { useSelector } from 'react-redux';

const Banner = () => {
  const [totalProfileCount, setTotalProfileCount] = useState(0);
  const categories = useSelector((state) => state.profile.categories);

  useEffect(() => {
    if (Array.isArray(categories)) {
      let count = 0;
      categories.forEach((category) => {
        count += category.total_candidates;
      });
      setTotalProfileCount(count);
    }
  }, [categories]);


  return (
    <div className={styles.bannerContainer}>
        <div className={styles.subBannercontainer}>
        <div className={styles.bannerHeading}>
        Find Bengaluru’s Best Tech Talent – Free, <br /> Fast, and Effortless with TABuddy
        </div>

        <div className={styles.bannerSubHeading}>
        TABuddy’s FREE sourcing engine searches, curates, and organizes top tech talent into easy-to-review buckets. All you need to do? Review, Shortlist and engage
        </div>

        <div className={styles.bannerButtons}>
            <button className={styles.bannerButton2}> {totalProfileCount}+ Candiates Sourced</button>
        </div>
      </div>
    </div>
  )
}

export default Banner