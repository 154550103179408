import React, { useState } from "react";
import styles from "../Animaton1.module.css";
import { Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CandidatesJobFilters from "../CandidateJobFilter";
import CandidateCardsLinkedinAnim2 from "./CandidateCardsLinkedinAnim2";

const { Title } = Typography;

const JobCandidiateSectionAnim2 = ({
  setShowMessage,
  show,
  setShow,
  showMessage,
}) => {
  const jobName = "Software Engineer"; // Static Job Name

  return (
    <div
      className={styles.jobCandidiateSectionContainer}
    >
      <div>
        <div className={styles.jobheadingContainer}>
          <div className={styles.jobheadingSubContainer}>
            <div>
              <Title level={4} style={{ margin: "0" }}>
                {jobName}
              </Title>
              <p
                style={{
                  color: `${show == 3 ? "#0077bd" : "#fff"}`,
                  fontSize: "12px",
                  marginTop: "4px",
                }}
              >
                2 unique searches done, 2500 profiles spotted, 1000 profiles
                reviewed
              </p>
            </div>
          </div>

          <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
            <button
              className={styles.candidateMessagebutton}
              style={{
                fontWeight: "500",
                padding: "0.5rem 0.5rem",
                fontSize: "10px",
                background: "#0778BD",
                color: "#fff",
              }}
            >
              <PlusOutlined /> <span> Create Campaign</span>
            </button>
          </div>
        </div>
      </div>

      <>
        <>
          <CandidatesJobFilters />
          <div className={styles.jobCandidatesSection}>
            < CandidateCardsLinkedinAnim2
              setShowMessage={setShowMessage}
              setShow={setShow}
            />
          </div>
        </>
      </>
    </div>
  );
};

export default JobCandidiateSectionAnim2;
