import React from "react";
import StepHeading from "./StepHeading";
import styles from "./Animation3.module.css";
import CampaignContainer from "./CampaignContainer";

const Anim = () => {
  return (
    <div className={styles.outreachMainContainer}>
      <div className={styles.headerContainer}>
        <StepHeading />
        <p className={styles.subheading}>
          These are the messages that will be sent out to the outbound
          candidates (present in our database).{" "}
        </p>
      </div>
      <CampaignContainer />
    </div>
  );
};

export default Anim;
