import React from "react";
import styles from "./monkbot.module.css";
import { LoadingOutlined } from "@ant-design/icons";

const CandidatesJobFilters = () => {
  // Static values
  const jobFilter = "New Pool";
  const allCandidatesFilter = true;
  const loadingCandidatesFilter = false;
  const candidateCounts = 20;

  return (
    <>
      <div className={styles.jobheadingFilters}>
        <div className={styles.jobSubHeadingFilters}>
          <button
            className={
              allCandidatesFilter
                ? styles.jobCandidiateHeadingFilterButtonSelected
                : styles.jobCandidiateHeadingFilterButtonNotSelected
            }
          >
            New Candidates
          </button>
          <button
            className={
              !allCandidatesFilter
                ? styles.jobCandidiateHeadingFilterButtonSelected
                : styles.jobCandidiateHeadingFilterButtonNotSelected
            }
          >
            Interested Candidates
          </button>
        </div>
      </div>

      {allCandidatesFilter && (
        <div className={styles.jobCandidatesFilterContainer}>
          <div className={styles.jobCandidatesFilterLeft}>
            <button
              className={`${styles.jobCandidatesFilterButtonAll} ${
                jobFilter === "New Pool" && styles.jobCandidatesFilterButtonAllSelected
              }`}
            >
              {jobFilter === "New Pool" && (
                <span className={styles.jobCandidatesFilterValue}>
                  {loadingCandidatesFilter ? <LoadingOutlined /> : candidateCounts}{" "}
                </span>
              )}
              <span className={styles.jobCandidatesFilterName}>New Pool</span>
            </button>

            <button
              className={`${styles.jobCandidatesFilterButtonAll} ${
                jobFilter === "shortlisted" && styles.jobCandidatesFilterButtonAllSelected
              }`}
            >
              {jobFilter === "shortlisted" && (
                <span className={styles.jobCandidatesFilterValue}>
                  {loadingCandidatesFilter ? <LoadingOutlined /> : candidateCounts}{" "}
                </span>
              )}
              <span className={styles.jobCandidatesFilterName}>Screen Select</span>
            </button>

            <button
              className={`${styles.jobCandidatesFilterButtonAll} ${
                jobFilter === "in_process" && styles.jobCandidatesFilterButtonAllSelected
              }`}
            >
              {jobFilter === "in_process" && (
                <span className={styles.jobCandidatesFilterValue}>
                  {loadingCandidatesFilter ? <LoadingOutlined /> : candidateCounts}{" "}
                </span>
              )}
              <span className={styles.jobCandidatesFilterName}>In Process</span>
            </button>

            <button
              className={`${styles.jobCandidatesFilterButtonAll} ${
                jobFilter === "No Responses" && styles.jobCandidatesFilterButtonAllSelected
              }`}
            >
              {jobFilter === "No Responses" && (
                <span className={styles.jobCandidatesFilterValue}>
                  {loadingCandidatesFilter ? <LoadingOutlined /> : candidateCounts}{" "}
                </span>
              )}
              <span className={styles.jobCandidatesFilterName}>No Responses</span>
            </button>

            <button
              className={`${styles.jobCandidatesFilterButtonAll} ${
                jobFilter === "Candidate Mismatch" && styles.jobCandidatesFilterButtonAllSelected
              }`}
            >
              {jobFilter === "Candidate Mismatch" && (
                <span className={styles.jobCandidatesFilterValue}>
                  {loadingCandidatesFilter ? <LoadingOutlined /> : candidateCounts}{" "}
                </span>
              )}
              <span className={styles.jobCandidatesFilterName}>Candidate Mismatch</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CandidatesJobFilters;
