import React, { useEffect, useState, useRef } from "react";
import styles from "./MoreInfo.module.css";

const MoreInfo = () => {
  const containerRef = useRef(null);

  // Track if the component is in view
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => setInView(true), 1000); // Start animation after 2s
        }
      },
      { threshold: 0.5 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const floatingButtons = [
    "Platform BIAS",
    "Boolean BIAS",
    "Persona BIAS",
    "Tech BIAS",
    "Designation BIAS",
    "Repetitive Data",
    "Duplicacy Demon",
    "Unstructured Sourcing",
    "Search Saturation",
    "Bad keyword choice",
    "Lack of Data Analysis",
    "Lack of Framework",
    "Bad skill clustering",
    "Overlooked Profiles",
    "Searching for JD",
  ];

  console.log(window.innerWidth)

  return (
    <div className={styles.moreInfoContainer} ref={containerRef}>
      <div className={styles.moreInfoSubContainer}>
        <h1 className={styles.moreInfoHeading}>
        Do you know how sourcing gaps increases your Time To Hire?
        </h1>
        <p className={styles.moreInfoSubHeading}>
        Recruiters miss 15-20% profiles on job boards & 40-60% profiles on LinkedIn due to :
        </p>
        {/* <button className={styles.moreInfoButton}>Read our Research</button> */}
      </div>

      {/* Floating, scattered buttons */}
      {inView && (
        <>
         {
          floatingButtons.map((button, index)=>(
            <div
            key={index}
            className={`${styles.buttonCommonStyle} ${styles[`moreInfoFloatingButton${index + 1}`]} ${styles.moreInfoFloatingButton}`}
          >
            {button}
          </div>
          ))
         }
        </>
      )}
    </div>
  );
};

export default MoreInfo;
