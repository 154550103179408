import React, { useEffect } from 'react'
import Index from '../components/NewDesign/Home/Index'
import ReactGA from "react-ga"


const Home = () => {
  
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname)
  },[])

  return (
    <>
    <Index />
    </>
  )
}

export default Home