import React from "react";
import styles from "./Info.module.css";
import Index from "../../../Animations/HeroAnimation/Index";

const Info = () => {
  return (
    <div className={styles.infoContainer}>
      <div className={styles.infoContentImage}>
      <Index />
      </div>

      <div className={styles.paddingBottom}></div>
    </div>
  );
};

export default Info;
