import React from "react";
import styles from "./Testimonials.module.css";
import styles2 from "../About/About.module.css";
import Marquee from "react-fast-marquee";

const Testimonials = () => {
  const testimonailCardsContent = [
    {
      name: "Mastanvali Shaik",
      positon: "Talent Acquisition Lead",
      compnay: "@Cloud Peritus",
      message:
        "“My accuracy level of submitting a quality resume increased for different requirements. The clients were very happy with my submission which made them run behind me!”",
      imgUrl: "./testimonials/testimonial1.jpeg",
    },
    {
      name: "Sayani Barik",
      positon: "Talent Acquisition Specialist",
      compnay: "@Birla Soft",
      message: `“Very Informative. I think it's the first time I attended this kind of a session. As recruiters, no one teaches us, everyone throws a JD and expects us to come up with results. So this session was very helpful for me.
                    My takeaways from this Sourcing Master Class were, "Able to write better boolean searches, Learnt how to break JD & try different search models, Got a better understanding of the Backend Roles.”`,
      imgUrl: "./testimonials/testimonial2.jpeg",
    },
    {
      name: "Ritika Gupta",
      positon: "Talent Acquisition Lead",
      compnay: "@EPAM Systems",
      message: `“Great sessions with good learning experience with no. of tips & tricks.
                    My takeaways from this Sourcing Master Class were, "Persona Understanding, Creating various search models using the negation method, URL analysis.”`,
      imgUrl: "./testimonials/testimonial3.jpeg",
    },
    {
      name: "Viswanatha Syam Devarakonda",
      positon: "RPO Program Manager",
      compnay: "@Wenger & Watson Inc.",
      message:
        "“This course is useful to recruiters who wants to Understand sourcing from Data Analysis, Just Started to implement & can see change in my productivity. My takeaways from this Sourcing Master Class were, 'Efficacy Models.' Overall the training structure was Excellent.”",
      imgUrl: "./testimonials/testimonial4.jpeg",
    },
    {
      name: "Tony Joseph Fernandez",
      positon: "Asst. Manager - HR Center of Excellence (COE)",
      compnay: "@PIT Solutions",
      message:
        "“Ashfaq, Yaswanth and the RecruitingMonk team have done it again. Another fab series of sessions with awesome insights and practical tips to make sense of data and datasets. The Master class let you slice & dice through the world of recruiting and explore a new data-driven approach & perspective. Keep rocking guys... you all are about to Revolutionise the Art of Recruiting!”",
      imgUrl: "./testimonials/testimonial5.jpeg",
    },
    {
      name: "Thirunavakkarasu Kannusamy",
      positon: "Manager - People Success",
      compnay: "@Bizongo",
      message:
        "“This session is more informative and I have learnt the Sourcing techniques, You are doing a great job, continue to do the same. My takeaways from this Sourcing Master Class were, 'How to negate the skills, Linkedin & Naukri Deep Sourcing.'”",
      imgUrl: "./testimonials/testimonial6.jpeg",
    },
    {
      name: "Vivian Kristoper",
      positon: "Talent Acquisition Specialist",
      compnay: "@CITI India",
      message:
        "“NOT operator is the first thing which really helped negate most of your duplicate data and NOT really helps to negate that and I've really learnt that here and I've also learnt to build the efficacy structure how do you break down your entire data once you get it and how do you break entire your JD. From sourcing perspective, how to dissect the data and dive through the grey areas of data that was very well explained which is something I am using it and it was really helpful.”",
      imgUrl: "./testimonials/testimonial7.jpeg",
    },
    {
      name: "Sunidhi Dubey",
      positon: "Resource Management",
      compnay: "@Deloitte",
      message:
        "“It definitely brought changes in my approach to sourcing specially when I used to see duplicate data again and again. How to actually bucket sourcing and the importance of persona understanding was really amazing. I remember you coming to the Noida office & exchanging with the whole floor, it was overall a very great session. Outlook of how things have to be approached and how we should maintain data and how we should bucket data, definitely that's gonna help us in our long run. It’s helping me so much.”",
      imgUrl: "./testimonials/testimonial8.jpeg",
    },
    {
      name: "Vicky Jindal",
      positon: "Building Teams",
      compnay: "@FixStock",
      message:
        "“Being into recruitment for 5 years, I was not able to structure my search & by going through this session, I was able to structure my search & build efficacy models that you've taught us. These are very much helpful.”",
      imgUrl: "./testimonials/testimonial9.jpeg",
    },
    {
      name: "Anita Ryan",
      positon: "People and Culture",
      compnay: "@Shopflo",
      message:
        "“Firstly I'd like to thank you for the session, it's really interesting & I've never probably looked at sourcing as a skill that I probably need to pick up. Over the past couple of days, I started building data & collecting data on my own. Sometimes when I am stuck, I go back to your videos & then find a way to go ahead or again start from scratch. It’s been interesting for me. I play around with words and numbers, which I never thought I would do because I was always scared of numbers. I am enjoying this now.”",
      imgUrl: "./testimonials/testimonial10.jpeg",
    },
    {
      name: "Amulya Gopal",
      positon: "Talent Partner",
      compnay: "@CRED",
      message:
        "“Would like to thank Recruiting Monk for well-organised training like this. Concepts discussed here were truly brilliant. Most of the times when I searched, I used to write multiple searches but get the same repetitive profiles. Now, after this session, I can build better search patterns. There are lots of takeaways from the session which we never heard before. Thank you for bringing these concepts in recruitment & helping recruiters.”",
      imgUrl: "./testimonials/testimonial11.jpeg",
    },
  ];

  return (
    <>
      <div className={styles2.aboutContainer}>
        <div className={styles2.aboutSubContainer}>
          <div className={styles2.aboutHeading}>
            What do the TA leaders say about Ashfaq's approach?
          </div>

          <div className={styles2.aboutSubHeading}>
            Ashfaq has trained more than 10,000 Tech Recruiters so far across
            varied level of exp, ranging from recruiters to directors from
            startups to GCCs to top product firms. He regularly contributes on
            LinkedIn & has written 1000+ posts with a reach of 10Million in last
            4+ years.
          </div>
        </div>
      </div>

      <div className={styles.testimonialsContainer}>
        <Marquee>
          <div className={styles.testimonialsSubContainer}>
            {testimonailCardsContent.slice(0, 5).map((data, index) => (
              <div className={styles.testimonialCard} key={index}>
                <div className={styles.testimonialHeader}>
                  <div className={styles.imgContainer}>
                    <img src={data.imgUrl} alt="" />
                  </div>

                  <div className={styles.headerContent}>
                    <div className={styles.name}>{data.name}</div>
                    <div className={styles.position}>{data.positon}</div>
                    <div className={styles.company}>{data.compnay}</div>
                  </div>
                </div>

                <div className={styles.testimonialBody}>{data.message}</div>
              </div>
            ))}
          </div>
        </Marquee>

        <Marquee direction="right">
          <div className={styles.testimonialsSubContainer}>
            {testimonailCardsContent.slice(5, 12).map((data, index) => (
              <div className={styles.testimonialCard} key={index}>
                <div className={styles.testimonialHeader}>
                  <div className={styles.imgContainer}>
                    <img src={data.imgUrl} alt="" />
                  </div>

                  <div className={styles.headerContent}>
                    <div className={styles.name}>{data.name}</div>
                    <div className={styles.position}>{data.positon}</div>
                    <div className={styles.company}>{data.compnay}</div>
                  </div>
                </div>

                <div className={styles.testimonialBody}>{data.message}</div>
              </div>
            ))}
          </div>
        </Marquee>
      </div>
    </>
  );
};

export default Testimonials;
