import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isAuthorized: localStorage.getItem("token") ? true : false,
  error: false,
  user: false,
  openLoginModal: false,
  userDetails: { firstName: "", lastName: "", email: "", referralCode:""},
  formSubmitLoader:false,
  waitListFormModal: false,
  addedToWaitListModal:false,
  isAdmin:false,
  adminLoadingButton:false,
  adminToken:null,
  wishListProfiles:[],
  grantAccessLoader:false,
  totalPages:0,
};

export const authSlice2 = createSlice({
  name: "auth2",
  initialState,

  reducers: {
    loginRequest: (state) => {
      state.loading = true;
    },
    setOpenLoginModal: (state, action) => {
      state.openLoginModal = action.payload;
    },
    loginSuccess: (state, action) => {
      state.isAuthorized = true;
      state.userDetails = action.payload;
      state.loading = false;
      state.error = false;
      localStorage.setItem("token", action.payload.token);
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = "some error occured";
      state.isAuthorized = false;
    },
    setformSubmitLoader:(state, action)=>{
      state.formSubmitLoader = action.payload;
    },
    setWaitListFormModal:(state, action)=> {
      state.waitListFormModal = action.payload;
    },
    setOpenAddedToWaitlistModal:(state, action)=>{
      state.addedToWaitListModal = action.payload; 
    },
    setLoadingButtons:(state, action) => {
      state.adminLoadingButton = action.payload;
    },
    setAdminToken:(state,action)=>{
      state.adminToken = action.payload;
    },
    setIsAdmin:(state, action)=>{
      state.isAdmin = action.payload;
    },
    setWishListProfiles:(state, action)=>{
      state.wishListProfiles = action.payload.profiles;
      state.totalPages = action.payload.totalPages;
    },
    setGrantAccess:(state, action)=>{
      state.grantAccessLoader = action.payload;
    },
    logoutSuccess: (state) => {
      state.isAuthorized = false;
      state.loading = false;
      state.error = "";
      state.adminToken = null;
      state.adminLoadingButton = false;
      state.isAdmin = false;
      state.userDetails = { firstName: "", lastName: "", email: "" };
    },    
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  getUserFailure,
  getUserRequest,
  getUserSuccess,
  setOpenLoginModal,
  setformSubmitLoader,
  setOpenAddedToWaitlistModal,
  setWaitListFormModal,
  setIsAdmin,
  setLoadingButtons,
  setAdminToken,
  setWishListProfiles,
  setGrantAccess
} = authSlice2.actions;

export default authSlice2.reducer;