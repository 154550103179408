import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useAuthData from "../api/useAuthData";
import { Spin } from "antd";

const LoginSuccess = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { getUserDetails } = useAuthData();

  useEffect(() => {
    const token =
      searchParams.get("accessToken") || localStorage.getItem("token");
    if (token) {
      getUserDetails(token);
    }
  }, []);

  return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
    <Spin />
  </div>;
};

export default LoginSuccess;
