import React from "react";
import styles from "./monkbot.module.css";
import { Tag, Typography, Tooltip } from "antd";

const JobCard = () => {
  const { Title, Paragraph } = Typography;

  // Static array for jobs data
  const jobsCard = [
    {
      _id: 1,
      designation: {
        designation: "Software Engineer",
      },
      minExperience: 2,
      maxExperience: 5,
      minCTC: 4,
      maxCTC: 10,
      locations: ["Bengaluru, Karnataka, India"],
      mandatorySkills: [
        { skill: "JavaScript" },
        { skill: "React" },
        { skill: "Node.js" },
        { skill: "AWS" },
      ],
    },
    {
      _id: 2,
      designation: {
        designation: "Data Scientist",
      },
      minExperience: 3,
      maxExperience: 7,
      minCTC: 8,
      maxCTC: 15,
      locations: ["Bengaluru, Karnataka, India"],
      mandatorySkills: [
        { skill: "Python" },
        { skill: "SQL" },
        { skill: "Machine Learnign" },
        { skill: "Data Analysis" },
      ],
    },
    {
      _id: 2,
      designation: {
        designation: "Python Developer",
      },
      minExperience: 2,
      maxExperience: 5,
      minCTC: 4,
      maxCTC: 10,
      locations: ["Bengaluru, Karnataka, India"],
      mandatorySkills: [
        { skill: "Python" },
        { skill: "Flask" },
        { skill: "Django" },
      ],
    },
  ];

  const getTagColor = (index) => {
    const colors = ["magenta", "red", "volcano", "orange", "gold"];
    return colors[index % colors.length];
  };

  return (
    <>
      {jobsCard.map((job, index) => (
        <div
          className={`${
            job._id === 1 ? styles.selectedJobCard : styles.jobcardContainer
          }`}
          key={index}
        >
          <div
            className={styles.jobCardDataContainer}
            style={{ display: "flex", flexDirection: "column", gap: "0.40rem" }}
          >
            {/* Title */}
            <div className={styles.jobCardInfo}>
              <div className={styles.row}>
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  {job.designation.designation}
                </div>
              </div>
            </div>

            {/* Experience */}
            <div className={styles.jobCardInfo}>
              <div className={styles.row}>
                <Paragraph
                  className={styles.rowHeading}
                  style={{ marginBottom: "0" }}
                >
                  Experience:
                </Paragraph>
                <div className={styles.rowSubHeading}>
                  {job.minExperience} - {job.maxExperience} Years
                </div>
              </div>
            </div>

            {/* CTC */}
            <div className={styles.jobCardInfo}>
              <div className={styles.row}>
                <Paragraph
                  className={styles.rowHeading}
                  style={{ marginBottom: "0" }}
                >
                  CTC:
                </Paragraph>
                <div className={styles.rowSubHeading}>
                  {job.minCTC} - {job.maxCTC} LPA
                </div>
              </div>
            </div>

            {/* Location */}
            <div className={styles.jobCardInfo}>
              <div className={`${styles.row} ${styles.locationRow}`}>
                <Paragraph
                  className={styles.rowHeading}
                  style={{ marginBottom: "0", width: "70px" }}
                >
                  Location:
                </Paragraph>
                <div className={styles.companyTags}>
                  {job.locations.slice(0, 2).map((location, index) => (
                    <Tag
                      color={getTagColor(index)}
                      key={index}
                      style={{ fontSize: "10px" }}
                    >
                      {location}
                    </Tag>
                  ))}
                  {job.locations.length > 2 && (
                    <Tooltip
                      title={job.locations.slice(2).join(" | ")}
                      placement="bottom"
                    >
                      <Tag
                        color="#1890ff"
                        style={{ fontSize: "10px", cursor: "pointer" }}
                      >
                        +{job.locations.length - 2}
                      </Tag>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>

            {/* Mandatory Skills */}
            <div className={styles.jobCardInfo}>
              <div className={styles.row} syle={{ gap: "0.15rem" }}>
                <Paragraph
                  className={styles.rowHeading}
                  style={{
                    marginBottom: "0",
                    minWidth: "120px",
                    maxWidth: "200px",
                  }}
                >
                  Mandatory Skills:
                </Paragraph>
                <div className={styles.jobTags}>
                  {job.mandatorySkills.slice(0, 3).map((skill, index) => (
                    <Tag
                      color={getTagColor(index)}
                      key={index}
                      style={{ fontSize: "10px" }}
                    >
                      {skill.skill}
                    </Tag>
                  ))}
                  {job.mandatorySkills.length > 3 && (
                    <Tooltip
                      title={job.mandatorySkills
                        .slice(3)
                        .map((skill) => skill.skill)
                        .join(" | ")}
                      placement="bottom"
                    >
                      <Tag
                        color="#1890ff"
                        style={{ cursor: "pointer", fontSize: "10px" }}
                      >
                        +{job.mandatorySkills.length - 3}
                      </Tag>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default JobCard;
