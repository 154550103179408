import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import SideBarEmail from "./SideBarEmail";
import InitialEmailMessage from "./InitialEmailMessage";
import FollowUpEmailMessage from "./FollowUpEmailMessage";
import CampaignCreatedPopUp from "./CampaignCreatedPopUp";

const EmailMessage = () => {
  const initialState = {
    subject: "",
    body: "",
    emailFollowUp: [{ followupLevel: 1, body: "" }],
    followupCount: 0,
    showPopup: false,
    selected: false,
  };

  const [subject, setSubject] = useState(initialState.subject);
  const [selected, setSelected] = useState(initialState.selected);
  const [body, setBody] = useState(initialState.body);
  const [emailFollowUp, setEmailFollowUp] = useState(
    initialState.emailFollowUp
  );
  const [followupCount, setFollowupCount] = useState(
    initialState.followupCount
  );
  const [showPopup, setShowPopup] = useState(initialState.showPopup);

  const bodyMessage = `Dear [Candidate's Name],

I hope this email finds you well. I am reaching out to you on behalf of RecruitingMonk with an exciting opportunity that I believe matches your skillset and experience perfectly.

We are currently looking for a talented and motivated Software Developer to join our dynamic team. At RecruitingMonk, we pride ourselves on creating innovative solutions that revolutionize the recruitment industry, and we are seeking a skilled developer who shares our passion for innovation and excellence.

As a Software Developer at RecruitingMonk, you will have the opportunity to work on cutting-edge projects, collaborate with a talented team of professionals, and contribute to the development of groundbreaking recruitment technology.

Key responsibilities of the role include:

Developing and maintaining software applications and systems
Participating in all phases of the software development lifecycle
Collaborating with cross-functional teams to design and implement solutions
Troubleshooting and debugging issues to ensure optimal performance
The ideal candidate will have:

Bachelor's degree in Computer Science, Engineering, or a related field
Proven experience in software development, with expertise in [specific technologies or programming languages relevant to the position]
Strong problem-solving skills and attention to detail
Excellent communication and teamwork abilities
This is an excellent opportunity for a driven and talented individual to take their career to the next level and make a meaningful impact in the recruitment technology space.

If you are interested in joining our team and contributing to our mission of revolutionizing the recruitment industry, please reply to this email with your updated resume attached. We would love to further discuss how your skills and experience align with the role.

Thank you for considering this opportunity, and I look forward to hearing from you soon.

Best regards,

[Your Name]

[Your Position]

RecruitingMonk`;
  const emailFollowUPMessage = {
    followupLevel: 1,
    body: `Dear [Candidate's Name],

I hope this email finds you well. I wanted to follow up on my previous message regarding the Software Developer position at RecruitingMonk.

We are very excited about the opportunity to potentially have you join our team and contribute your skills and expertise to our innovative projects. We believe that your background and experience make you a strong candidate for this role, and we would love to further discuss how you can make an impact at RecruitingMonk.

If you have any questions about the position or would like to schedule a call to learn more about our company and culture, please don't hesitate to reach out. We are here to provide any additional information you may need and to assist you throughout the application process.

Thank you again for considering this opportunity with RecruitingMonk. We are looking forward to the possibility of working together and are eager to hear from you soon.

Best regards,

[Your Name]

[Your Position]

RecruitingMonk`,
  };

  const cursorRef = useRef(null);

  // Function to move the cursor and update the component state
  const moveCursor = () => {
    const screenWidth = window.innerWidth;
    if (cursorRef.current) {
      cursorRef.current.style.visibility = "visible"; // Ensure the element is found
      if (screenWidth < 1400) {
        cursorRef.current.style.top = "18%";
        cursorRef.current.style.left = "67%";
      }else{
        cursorRef.current.style.top = "14%";
        cursorRef.current.style.left = "67%";
      }
      cursorRef.current.style.zIndex = "100";

      setTimeout(() => {
        if (cursorRef.current) {
          setSelected(true);
          setSubject(
            "Exciting Opportunity: Join Our Team as a Python Developer at RecruitingMonk!"
          );
          setBody(bodyMessage);
          setEmailFollowUp([emailFollowUPMessage]); // Ensure it's an array
        }
      }, 3000);
    } else {
      console.warn("Cursor element not found");
    }
  };

  // Function to reset the cursor and component state
  const resetCursor = () => {
    if (cursorRef.current) {
      // if (screenWidth > 1400) {

      cursorRef.current.style.top = "95%";
      cursorRef.current.style.left = "91%";
      // }
      cursorRef.current.style.zIndex = "1000";

      setTimeout(() => {
        if (cursorRef.current) {
          cursorRef.current.style.visibility = "hidden";
          cursorRef.current.style.top = "3%";
          cursorRef.current.style.left = "3%";
        }
        setShowPopup(true);
        setSelected(false);
      }, 4000); // Show popup after 4 seconds

      // Reset everything after 9 seconds
      setTimeout(() => {
        setShowPopup(false);
        setSubject(initialState.subject);
        setBody(initialState.body);
        setEmailFollowUp(initialState.emailFollowUp);
        setFollowupCount(initialState.followupCount);
      }, 9000); // Reset after 9 seconds
    } else {
      console.warn("Cursor element not found");
    }
  };

  useEffect(() => {
    const loopCursorMovement = () => {
      moveCursor(); // Start the cursor movement

      setTimeout(() => {
        resetCursor(); // Reset the cursor and state

        // After resetCursor completes, wait for 5 seconds before starting the loop again
        setTimeout(() => {
          loopCursorMovement(); // Recursive call to loopCursorMovement for infinite loop
        }, 12000); // 5-second break between iterations
      }, 6000); // Time to reset cursor after movement and email typing
    };

    // Start the loop initially
    loopCursorMovement();

    // Clean up if the component is unmounted
    return () => {
      clearTimeout(loopCursorMovement); // Clear any timeout if component unmounts
    };
  }, []); // Only run once when the component mounts

  return (
    <div
      style={{
        height: "80vh",
        overflow: "hidden",
        minWidth: "100%",
        position: "relative",
      }}
    >
      {showPopup && <CampaignCreatedPopUp />}

      <Row
        style={{
          height: "80vh",
          overflow: "hidden",
          minWidth: "100%",
          opacity: `${showPopup ? "0.5" : "1"}`,
        }}
      >
        <img
          ref={cursorRef}
          src="./img/animation/cursor1.png"
          alt=""
          width={"2.5%"}
          style={{
            position: "absolute",
            top: "2%",
            left: "3%",
            zIndex: "1000",
            transition: "top 1s ease, left 1s ease", // Smooth transition
          }}
        />
        <Col
          style={{
            width: "65%",
            height: "100%",
            overflowY: "hidden",
            borderRight: "2px solid #a4a4a4",
          }}
        >
          <InitialEmailMessage
            subject={subject}
            setSubject={setSubject}
            body={body}
            setBody={setBody}
          />
          <FollowUpEmailMessage
            emailFollowUp={emailFollowUp}
            setEmailFollowUp={setEmailFollowUp}
            followupCount={followupCount}
            setFollowupCount={setFollowupCount}
          />
        </Col>

        <Col
          style={{
            width: "35%",
            borderLeft: "2px solid #F2F2F2",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SideBarEmail
            subject={subject}
            setSubject={setSubject}
            body={body}
            setBody={setBody}
            emailFollowUp={emailFollowUp}
            setEmailFollowUp={setEmailFollowUp}
            setFollowupCount={setFollowupCount}
            selected={selected}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EmailMessage;
