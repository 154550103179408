import React, { useEffect, useState } from "react";
import styles from "./Admin.module.css";
import useAuthData from "../../api/useAuthData";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Button,
  TablePagination,
} from "@mui/material";

const AdminTable = () => {
  const { getWishlistUser, grantAppAccess } = useAuthData();
  const [id, setId] = useState("");
  const [page, setPage] = useState(0); // Current page, starting from 0
  const rowsPerPage = 20; // Fixed to 20 rows per page

  const totalPages = useSelector((state) => state.auth2.totalPages); // Total number of pages
  const profiles = useSelector((state) => state.auth2.wishListProfiles);
  const grantAccessLoader = useSelector(
    (state) => state.auth2.grantAccessLoader
  );
  const adminLoadingButton = useSelector(
    (state) => state.auth2.adminLoadingButton
  );

  // Fetch profiles whenever the page changes
  useEffect(() => {
    getWishlistUser(page + 1, rowsPerPage); // Page is 0-indexed, API expects 1-indexed
  }, [page]);

  const handleGrantAccess = (id) => {
    grantAppAccess(id);
    setId(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Update current page
  };

  return (
    <div className={styles.tableContainer}>
      {adminLoadingButton ? (
        "Loading..."
      ) : (
        <div>
          <TableContainer
            component={Paper}
            sx={{
              marginTop: "0.5rem",
              width: "100vw",
              overflowY: "scroll",
              overflowX: "hidden",
              maxHeight: "90vh",
              background: "#e2e2e2",
            }}
          >
            <Table
              sx={{ minWidth: 650, borderCollapse: "collapse" }}
              aria-label="user table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#000",
                    borderBottom: "2px solid #000",
                  }}
                >
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Phone
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    LinkedIn
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Current Company
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Designation
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      borderRight: "1px solid #ccc",
                    }}
                  >
                    Hiring Locations
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                    Access
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profiles.map((user, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: "1px solid #ccc",
                      borderBottom: "1.5px solid #fff",
                    }}
                  >
                    <TableCell
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "150px",
                        borderRight: "1.5px solid #fff",
                      }}
                    >
                      {user.firstName + user.lastName}
                    </TableCell>
                    <TableCell
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "200px",
                        borderRight: "1.5px solid #fff",
                      }}
                    >
                      {user.email}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1.5px solid #fff" }}>
                      {user.phoneNumber}
                    </TableCell>
                    <TableCell sx={{ borderRight: "1.5px solid #fff" }}>
                      {user.linkedinUrl && (
                        <Link
                          href={user.linkedinUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          LinkedIn Profile
                        </Link>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "150px",
                        borderRight: "1.5px solid #fff",
                      }}
                    >
                      {user.currentCompany}
                    </TableCell>
                    <TableCell
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "150px",
                        borderRight: "1.5px solid #fff",
                      }}
                    >
                      {user.designation}
                    </TableCell>
                    <TableCell
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "200px",
                        borderRight: "1.5px solid #fff",
                      }}
                    >
                      {user.hiringLocations?.join(", ")}
                    </TableCell>
                    <TableCell>
                      <Button
                        disabled={user.isEnabled || grantAccessLoader}
                        variant="contained"
                        color="primary"
                        onClick={() => handleGrantAccess(user._id)}
                      >
                        {grantAccessLoader && user._id === id
                          ? "Waiting"
                          : "Grant Access"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]} // Disable rowsPerPage selection
            component="div"
            count={totalPages * rowsPerPage} // Total items = total pages * rows per page
            rowsPerPage={rowsPerPage} // Rows per page fixed at 20
            page={page} // Current page
            onPageChange={handleChangePage} // Handle page change
          />
        </div>
      )}
    </div>
  );
};

export default AdminTable;
