import React, { useEffect } from "react";
import Main from "./pages/Main";
import Box from "@mui/material/Box";
import { Route, Routes } from "react-router-dom";
import ChatSection from "./components/ChatSection";
import ErrorPage from "./pages/ErrorPage";
import NewChat from "./components/NewChat";
import LoginSuccess from "./pages/LoginSuccess";
import InitialLoginPage from "./pages/InitialLoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import LoginFailure from "./pages/LoginFailure";
import About from "./pages/About";
import Services from "./pages/Services";
import PreviewCertificate from "./pages/PreviewCertificate";
import ShareCertificate from "./pages/ShareCertificate";
import { getDatabase, onValue, ref } from "firebase/database";
import { app } from "./firebase.js";

import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
} from "@mui/material";
import ScoringGaps from "./pages/ScoringGaps";
import Home from "./pages/Home";
import LiveSourcing from "./pages/LiveSourcing";
import QuickHire from "./pages/QuickHire";
import LoginModal from "./components/NewDesign/sharedComponents/LoginModal/LoginModal";
import Privacy from "./pages/Privacy.jsx";
import WaitList from "./pages/WaitList.jsx";
import ReactGA from "react-ga"
import ErrorPopupModal from "./components/NewDesign/sharedComponents/AddedToWaitlistPopup/AddedToWaitlistPopup.jsx";
import AdminPage from "./pages/Admin.jsx";

function App() {
  
  const TRACKING_ID = "G-94V417M9F2"
  ReactGA.initialize(TRACKING_ID) 
  const isMobile = useMediaQuery("(max-width: 768px)");

  // Transition for dialog box
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <>
      <ErrorPopupModal/>
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        maxHeight="100vh"
        sx={{ flexGrow: 1 }}
      >
        <LoginModal />
        {isMobile ? (
          <>
            <Dialog
              open={true}
              TransitionComponent={Transition}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"TA BUDDY"}</DialogTitle>
              <DialogContent>
                <p>Hey TA,</p>
                <p style={{ textAlign: "justify" }}>
                  Thanks for hitting up on me. Unfortunately I'm available only
                  on web mode right now. Check me out via laptop/desktop.
                </p>
                <p>
                  Meanwhile, you can follow{" "}
                  <a
                    target="_blank"
                    href={"https://www.linkedin.com/in/ashfaqahmedhr/"}
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    Ashfaq
                  </a>{" "}
                  on LinkedIn, the inventor of tabuddy
                </p>
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <Routes>
            {/* <Route exact path="/" element={<InitialLoginPage />} />
            <Route
            exact
            path="learning/certificate/:imageUrl"
            element={<ShareCertificate />}
            />
              <Route
              path="app"
              element={
                <ProtectedRoute>
                <Main />
                </ProtectedRoute>
                }
                >
                <Route exact path="chat" element={<NewChat />} />
                <Route
                exact
                path="learning/certificate"
                element={<PreviewCertificate />}
                />
                <Route
                exact
                path="chats/:category/:chatId"
                element={<ChatSection />}
              />
              <Route exact path="about" element={<About />} />
              <Route exact path="services" element={<Services />} />
              </Route> */}
            <Route path="login-failure" element={<LoginFailure />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="login-success" element={<LoginSuccess />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="live-sourcing" element={<LiveSourcing />} />
            {/* <Route exact path="quick-hire" element={<QuickHire />} /> */}
            <Route exact path="check-bias" element={<ScoringGaps />} />
            <Route exact path="login-waitlist" element={<WaitList />} />
            <Route exact path="login-waitlist/success" element={<LoginSuccess />} />
            <Route exact path="admin" element={<AdminPage />} />

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        )}
      </Box>
    </>
  );
}

export default App;