import React from "react";
import styles from "./Banner.module.css";

const Banner = () => {
  return (
    <div className={styles.bannerContainer}>
      <div className={styles.subBannercontainer}>
        <div className={styles.bannerHeading}>
          Not Just Another AI Tool – Meet TABuddy, <br />
          Your Outcome-Driven TA Partner
        </div>

        <div className={styles.bannerSubHeading}>
          Experience the world’s first Tech Hiring platform, designed to MIMIC
          the way top 1% recruiters source, shortlist & engage profiles across
          job boards and LinkedIn.
        </div>

        <div className={styles.bannerButtons}>
          <button
            className={styles.bannerButton1}
            onClick={() =>
              window.open(
                "https://wa.me/917996496999",
                "_blank"
              )
            }
          >
            Request for Demo
          </button>
        </div>

        <div className={styles.sourcingProcess}>
          <div className={styles.sourcingStep}>
            <div className={styles.sourcingImage}>
              <img src="./img/step1.svg" alt="" />
            </div>
            <div className={styles.sourcingText}>Creates Searches</div>
          </div>

          <img src="./img/arrowDirection.svg" alt="" />

          <div className={styles.sourcingStep}>
            <div className={styles.sourcingImage}>
              <img src="./img/step2.1.svg" alt="" />
              <img src="./img/step2.2.svg" alt="" />
            </div>
            <div className={styles.sourcingText}>
              Sources & Shortlists across Platforms
            </div>
          </div>

          <img
            src="./img/arrowDirection.svg"
            alt=""
            className={styles.mirrorArrow}
          />

          <div className={styles.sourcingStep}>
            <div className={styles.sourcingImage}>
              <img src="./img/step3.svg" alt="" />
            </div>
            <div className={styles.sourcingText}>Engages with Candidates</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
