import React, { useEffect, useRef } from "react";
import styles from "./Animaton1.module.css";
import { Button, Checkbox, Dropdown, Space, Typography, MenuProps } from "antd";
import { DownOutlined } from "@ant-design/icons";

const CandidateCardLinkedin = ({ setShowMessage, setShow }) => {
  const candidates = [
    {
      id: 1,
      name: "Ajay Mittal Singh",
      experience_in_year: "5 Years",
      location: "Bengaluru",
      current_experience: {
        designation: "Software Engineer",
        time_period: { startDate: { month: 1, year: 2018 } },
        company_name: "Tech Corp",
        company_details: {
          industries: ["Technology"],
          staff_range: { start: 200, end: 500 },
        },
        category: "Full-Time",
      },
      previous_experience: {
        designation: "Junior Developer",
        time_period: {
          startDate: { month: 1, year: 2015 },
          endDate: { month: 12, year: 2017 },
        },
        company_name: "Code Works",
        company_details: {
          industries: ["Software"],
          staff_range: { start: 50, end: 200 },
        },
        category: "Contract",
      },
      skills: ["JavaScript", "React", "Node.js", "CSS", "HTML"],
      sourced_at: "2023-09-10",
      profile_type: "linkedin",
      notice_period: "2 Months",
      expected_salary: "12 LPA",
    },
    {
      id: 2,
      name: "Rahul Sharma",
      experience_in_year: "4 Years",
      location: "Bengaluru",
      current_experience: {
        designation: "Frontend Developer",
        time_period: { startDate: { month: 3, year: 2019 } },
        company_name: "Web Innovations",
        company_details: {
          industries: ["Web Development"],
          staff_range: { start: 100, end: 250 },
        },
        category: "Full-Time",
      },
      previous_experience: {
        designation: "Intern Developer",
        time_period: {
          startDate: { month: 6, year: 2017 },
          endDate: { month: 2, year: 2019 },
        },
        company_name: "Startup Works",
        company_details: {
          industries: ["Tech Startup"],
          staff_range: { start: 20, end: 50 },
        },
        category: "Internship",
      },
      skills: ["HTML", "CSS", "JavaScript", "React", "Redux"],
      sourced_at: "2023-09-12",
      profile_type: "linkedin",
      notice_period: "1 Month",
      expected_salary: "8 LPA",
    },
    {
      id: 3,
      name: "Manoj Bakkar",
      experience_in_year: "6 Years",
      location: "Bengaluru",
      current_experience: {
        designation: "Project Manager",
        time_period: { startDate: { month: 2, year: 2017 } },
        company_name: "Management Solutions",
        company_details: {
          industries: ["Consulting"],
          staff_range: { start: 150, end: 300 },
        },
        category: "Full-Time",
      },
      previous_experience: {
        designation: "Assistant Manager",
        time_period: {
          startDate: { month: 8, year: 2014 },
          endDate: { month: 1, year: 2017 },
        },
        company_name: "Consultancy Experts",
        company_details: {
          industries: ["Consulting"],
          staff_range: { start: 100, end: 200 },
        },
        category: "Full-Time",
      },
      skills: ["Project Management", "Scrum", "Agile", "JIRA"],
      sourced_at: "2023-09-08",
      profile_type: "linkedin",
      notice_period: "3 Months",
      expected_salary: "15 LPA",
    },
    {
      id: 4,
      name: "Vikram Desai",
      experience_in_year: "8 Years",
      location: "Bengaluru",
      current_experience: {
        designation: "Backend Developer",
        time_period: { startDate: { month: 9, year: 2016 } },
        company_name: "DataWorks",
        company_details: {
          industries: ["Data Science"],
          staff_range: { start: 250, end: 600 },
        },
        category: "Full-Time",
      },
      previous_experience: {
        designation: "Database Administrator",
        time_period: {
          startDate: { month: 4, year: 2013 },
          endDate: { month: 8, year: 2016 },
        },
        company_name: "DB Solutions",
        company_details: {
          industries: ["Database Management"],
          staff_range: { start: 80, end: 150 },
        },
        category: "Full-Time",
      },
      skills: ["Node.js", "Express", "MongoDB", "SQL", "Python"],
      sourced_at: "2023-09-15",
      profile_type: "linkedin",
      notice_period: "2 Months",
      expected_salary: "14 LPA",
    },
    {
      id: 5,
      name: "Priya Iyer",
      experience_in_year: "7 Years",
      location: "Bengaluru",
      current_experience: {
        designation: "UI/UX Designer",
        time_period: { startDate: { month: 10, year: 2017 } },
        company_name: "Creative Designs",
        company_details: {
          industries: ["Design"],
          staff_range: { start: 50, end: 120 },
        },
        category: "Full-Time",
      },
      previous_experience: {
        designation: "Graphic Designer",
        time_period: {
          startDate: { month: 1, year: 2014 },
          endDate: { month: 9, year: 2017 },
        },
        company_name: "Design Studio",
        company_details: {
          industries: ["Creative"],
          staff_range: { start: 30, end: 60 },
        },
        category: "Contract",
      },
      skills: ["Figma", "Sketch", "Photoshop", "Illustrator", "InVision"],
      sourced_at: "2023-09-14",
      profile_type: "linkedin",
      notice_period: "1 Month",
      expected_salary: "10 LPA",
    },
  ];

  const cursorRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const screenWidth = window.innerWidth;

    const moveCursor = () => {
      if (cursorRef.current) {
        if(screenWidth < 1400){
          cursorRef.current.style.top = "62%"; // Move to 73%
          cursorRef.current.style.left = "85%"; // Move to 85%
        }else{
          cursorRef.current.style.top = "48%"; // Move to 73%
          cursorRef.current.style.left = "90%"; // Move to 85%
        }
        cursorRef.current.style.zIndex = "100"; // Ensure it's on top
        buttonRef.current.click(); // Programmatically click the button
        buttonRef.current.style.backgroundColor = "#0077bd"; // Change button color
        buttonRef.current.style.color = "#fff"; // Change text color
        setTimeout(() => setShowMessage(true), 2000);
      }
    };

    const resetCursor = () => {
      setTimeout(() => {
        if (cursorRef.current) {
          cursorRef.current.style.top = "40%"; // Reset to 10%
          cursorRef.current.style.left = "45%"; // Reset to 8%
          cursorRef.current.style.zIndex = 1000;
          // cursorRef.current.style.display = "none"; // Hide cursor
          setShowMessage(false);
          setShow(1);
        }
      }, 6000);
    };

    // Move the cursor immediately
    setTimeout(() => moveCursor(), 5000);

    // Set an interval to reset the cursor
    const intervalId = setInterval(() => {
      resetCursor();
    }, 6000); // Reset every 6 seconds

    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, []);

  const items = [
    {
      label: "Interested",
      key: "interested",
    },
    {
      label: "Screen Select",
      key: "shortlisted",
    },
    {
      label: "In Process",
      key: "in_process",
    },
    {
      label: "No Response",
      key: "no_response",
    },
    {
      label: "Candidate Mismatch",
      key: "candidate_mismatch",
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <div className={styles.candidatesCards}>
      <div className={styles.candtidatesMessageActionAllShow}>
        Create your ranking algorithm
      </div>

      <img
        ref={cursorRef}
        src="./img/animation/cursor1.png"
        alt=""
        width={"2%"}
        style={{
          position: "absolute",
          top: "-6%",
          left: "2%",
          zIndex: "1000",
          transition: "top 1s ease, left 1s ease", // Smooth transition
        }}
      />
      {candidates.map((candidate, key) => (
        <div className={styles.candidatesCard} key={key}>
          <div className={styles.candidateCardHeading}>
            <div className={styles.candidateName}>
              <Checkbox />
              <Typography className={styles.candidateCardTitle}>
                {candidate.name}
              </Typography>
            </div>

            <div className={styles.cnadidateStatusContaier}>
              <Dropdown menu={menuProps}>
                <Button style={{ fontSize: "10px", width: "130px" }}>
                  <Space>
                    Candidate Status
                    <DownOutlined style={{ fontSize: "10px", width: "20px" }} />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </div>

          <div className={styles.candidateCardSubHeading}>
            <div className={styles.candidateCardBasicInfoHeading}>
              <img
                className={styles.candidateCardIcon}
                src="./img/animation/experience.jpg"
                alt="Experience"
              />
              <Typography className={styles.candidateCardBasicInfoTitle}>
                {candidate.experience_in_year}
              </Typography>
            </div>

            <div className={styles.candidateCardBasicInfoHeading}>
              <img
                className={styles.candidateCardIcon}
                src="./img/animation/location.jpg"
                alt="Location"
              />
              <Typography className={styles.candidateCardBasicInfoTitle}>
                {candidate.location}
              </Typography>
            </div>

            <div className={styles.candidateCardBasicInfoHeading}>
              <img
                className={styles.candidateCardIcon}
                src="./img/animation/wallet.jpg"
                alt="Location"
              />
              <Typography className={styles.candidateCardBasicInfoTitle}>
                {candidate.expected_salary}
              </Typography>
            </div>

            <div className={styles.candidateCardBasicInfoHeading}>
              <img
                className={styles.candidateCardIcon}
                src="./img/animation/np.jpg"
                alt="Location"
              />
              <Typography className={styles.candidateCardBasicInfoTitle}>
                {candidate.notice_period}
              </Typography>
            </div>
          </div>

          <div className={styles.candidatesDetails}>
            <div className={styles.candidateRow}>
              <div className={styles.candidateRowHeading}>
                <Typography style={{ color: "#808080", fontSize: "10px" }}>
                  Current
                </Typography>
              </div>
              {candidate.current_experience ? (
                <div className={styles.candidateRowDetails}>
                  <Typography style={{ fontSize: "10px" }}>
                    {candidate.current_experience?.designation}{" "}
                    <span style={{ color: "#808080" }}>for </span>5 Years and 8
                    Months
                  </Typography>
                  <div className={styles.candidateJobExperienceData}>
                    <div className={styles.candidateJobExperienceCompany}>
                      <div className={styles.candidatecompany_name}>
                        <span
                          style={{ color: "#808080", paddingRight: "0.25rem" }}
                        >
                          @
                        </span>
                        <Typography.Link
                          style={{
                            textDecoration: "underline",
                            color: "#0778BD",
                            fontSize: "9px",
                          }}
                        >
                          {candidate.current_experience.company_name}
                        </Typography.Link>
                      </div>
                      {candidate.current_experience?.category && (
                        <div>
                          <div className={styles.candidateCompanyType}>
                            {candidate.current_experience?.category}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.candidateIndustryType}>
                      {candidate.current_experience.company_details?.industries?.map(
                        (data, index) => (
                          <span style={{ fontSize: "10px" }} key={index}>
                            {data} |{" "}
                          </span>
                        )
                      )}
                      {candidate.current_experience.company_details
                        ?.staff_range &&
                        `
                        ${candidate.current_experience.company_details?.staff_range.start} 
                        - ${candidate.current_experience.company_details?.staff_range.end} employees`}
                    </div>
                  </div>
                </div>
              ) : (
                "Not Mentioned"
              )}
            </div>

            <div className={styles.candidateRow}>
              <div className={styles.candidateRowHeading}>
                <Typography style={{ color: "#808080", fontSize: "10px" }}>
                  Recent Skill Stack
                </Typography>
              </div>
              <div className={styles.candidateRowDetails}>
                {candidate.skills?.slice(0, 5).join(" | ")}{" "}
                {candidate.skills.length > 5
                  ? `|+${candidate.skills.length - 5} more`
                  : ""}
              </div>
            </div>

            <div className={styles.candidateRow}>
              <div className={styles.candidateRowHeading}>
                <Typography style={{ color: "#808080", fontSize: "10px" }}>
                  Previous
                </Typography>
              </div>
              {candidate.previous_experience ? (
                <div className={styles.candidateRowDetails}>
                  <Typography style={{ fontSize: "10px" }}>
                    {candidate.previous_experience?.designation}{" "}
                    <span style={{ color: "#808080" }}>for </span>2 Years and 11
                    Months
                  </Typography>
                  <div className={styles.candidateJobExperienceData}>
                    <div className={styles.candidateJobExperienceCompany}>
                      <div className={styles.candidatecompany_name}>
                        <span
                          style={{
                            color: "#808080",
                            paddingRight: "0.25rem",
                            fontSize: "9px",
                          }}
                        >
                          @
                        </span>
                        <Typography.Link
                          style={{
                            textDecoration: "underline",
                            color: "#0778BD",
                            fontSize: "10px",
                          }}
                        >
                          {candidate.previous_experience.company_name}
                        </Typography.Link>
                      </div>
                      {candidate.previous_experience?.category && (
                        <div>
                          <div className={styles.candidateCompanyType}>
                            {candidate.previous_experience?.category}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.candidateIndustryType}>
                      {candidate.previous_experience.company_details?.industries?.map(
                        (data, index) => (
                          <span key={index}>{data} | </span>
                        )
                      )}
                      {candidate.previous_experience.company_details
                        ?.staff_range &&
                        `
                        ${candidate.previous_experience.company_details?.staff_range.start} 
                        - ${candidate.previous_experience.company_details?.staff_range.end} employees`}
                    </div>
                  </div>
                </div>
              ) : (
                "Not Mentioned"
              )}
            </div>
          </div>

          <div className={styles.candtidatesMessageAction}>
            <Typography style={{ fontSize: "10px" }}>
              <span style={{ color: "#808080", paddingRight: "0.25rem" }}>
                Sourced
              </span>{" "}
              <span style={{ fontWeight: "500", fontSize: "10px" }}>
                5 days ago
              </span>
            </Typography>

            <Button
              ref={buttonRef}
              type="primary"
              size="large"
              style={{
                height: "20px",
                fontSize: "8px",
                background: "#fff",
                color: "#0778BD",
                border: "1.25px solid #0778BD",
              }}
            >
              Send email
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CandidateCardLinkedin;
