import React, { useState } from "react";
import styles from "./Navbar.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import TemporaryDrawer from "./NavbarMobileView";
import { useDispatch, useSelector } from "react-redux";
import {
  logoutSuccess,
  setOpenLoginModal,
} from "../../../../features/auth2/authSlice2";
import { Dropdown, message, Menu, Space } from "antd";
import NameAvatar from "./NavbarAvatar";
import { setResetProfileState } from "../../../../features/profileSlice/profileSlice";
import { persistor } from "../../../../app/store";

const Navbar = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth2.isAuthorized);
  const userDetails = useSelector((state) => state.auth2.userDetails);
  

  const items = [
    {
      label: `${userDetails.firstName} ${userDetails.lastName}`,
      key: "1",
    },
    {
      label: userDetails.email, 
      key: "2",
    },
    // {
    //   label: "Refferal Codes", 
    //   key: "4",
    //   children: [
    //     {
    //       label: `${userDetails.referralCode}`, 
    //       key: "6",
    //       onClick: () => {
            
    //         navigator.clipboard.writeText(userDetails.referralCode)
    //           .then(() => {
    //             message.success("Referral Code copied to clipboard");
    //           })
    //           .catch(() => {
    //             message.error("Failed to copy Referral Code");
    //           });
    //       }
    //     },
    //   ]
    // },
    {
      type: "divider", // Add a visual separator before the logout button
    },
    {
      label: "Logout", // Logout option
      key: "3",
      onClick: () => {
        // Clear Redux state and localStorage
        dispatch(logoutSuccess());
        dispatch(setResetProfileState());
        localStorage.removeItem("token");
        message.success("Logout Successfully!");
  
        // Clear persisted data for this slice
        persistor.purge();
      },
    },
  ];

  return (
    <div className={styles.navbarContainer}>
      <img src="./img/tabuddyLogo.svg" alt="" />

      <div className={`${styles.navbarMidContainer}`}>
        <span
          onClick={() => navigate("/")}
          className={`${styles.navbarOption} ${
            location.pathname === "/" ? styles.selected : ""
          }`}
        >
          Home
        </span>
        <span
          onClick={() => navigate("/live-sourcing")}
          className={`${styles.navbarOption} ${
            location.pathname === "/live-sourcing" ? styles.selected : ""
          }`}
        >
          Live Sourcing
        </span>
        {/* <span
          onClick={() => navigate("/check-bias")}
          className={`${styles.navbarOption} ${
            location.pathname === "/check-bias" ? styles.selected : ""
          }`}
        >
          Check Bias
        </span> */}
        {/* <span
          onClick={() => navigate("/quick-hire")}
          className={`${styles.navbarOption} ${
            location.pathname === "/quick-hire" ? styles.selected : ""
          }`}
        >
          Quick Hire
        </span> */}
        {/* <span
          onClick={() => navigate("/pricing")}
          className={`${styles.navbarOption} ${
            location.pathname === "/pricing" ? styles.selected : ""
          }`}
        >
          Pricing
        </span> */}
      </div>

      <div className={styles.loginButtonContainer}>
        {isAuth ? (
          <Dropdown overlay={<Menu items={items} />} trigger={['hover']} style={{ cursor: "pointer" }}>
          <Space>
            <NameAvatar />
          </Space>
        </Dropdown>
        
        ) : (
          <button
            className={styles.loginButton}
            onClick={() => {
              dispatch(setOpenLoginModal(true));
            }}
          >
            Login
          </button>
        )}
      </div>

      <div className={styles.mobileLoginContainer}>
        <TemporaryDrawer />
      </div>
    </div>
  );
};

export default Navbar;