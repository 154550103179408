import React, { useEffect, useRef, useState } from "react";
import styles from "../Animaton1.module.css";
import { Button, Checkbox, Dropdown, Space, Typography, MenuProps } from "antd";
import { DownOutlined } from "@ant-design/icons";
import RankFilters from "./RankFilters";

const CandidateCardsLinkedinAnim2 = ({ setShowMessage, setShow }) => {
  const [jobFilter, setJobFilter] = useState("All");
  const candidates1 = [
    {
      id: 1,
      name: "Ajay Mittal Singh",
      experience_in_year: "5 Years",
      location: "Bengaluru",
      current_experience: {
        designation: "Software Engineer",
        time_period: { startDate: { month: 1, year: 2018 } },
        company_name: "Tech Corp",
        company_details: {
          industries: ["Technology"],
          staff_range: { start: 200, end: 500 },
        },
        category: "Full-Time",
      },
      previous_experience: {
        designation: "Junior Developer",
        time_period: {
          startDate: { month: 1, year: 2015 },
          endDate: { month: 12, year: 2017 },
        },
        company_name: "Code Works",
        company_details: {
          industries: ["Software"],
          staff_range: { start: 50, end: 200 },
        },
        category: "Contract",
      },
      skills: ["JavaScript", "React", "Node.js", "CSS", "HTML"],
      sourced_at: "2023-09-10",
      profile_type: "linkedin",
      notice_period: "2 Months",
      expected_salary: "12 LPA",
    },
    {
      id: 2,
      name: "Rahul Sharma",
      experience_in_year: "4 Years",
      location: "Bengaluru",
      current_experience: {
        designation: "Frontend Developer",
        time_period: { startDate: { month: 3, year: 2019 } },
        company_name: "Web Innovations",
        company_details: {
          industries: ["Web Development"],
          staff_range: { start: 100, end: 250 },
        },
        category: "Full-Time",
      },
      previous_experience: {
        designation: "Intern Developer",
        time_period: {
          startDate: { month: 6, year: 2017 },
          endDate: { month: 2, year: 2019 },
        },
        company_name: "Startup Works",
        company_details: {
          industries: ["Tech Startup"],
          staff_range: { start: 20, end: 50 },
        },
        category: "Internship",
      },
      skills: ["HTML", "CSS", "JavaScript", "React", "Redux"],
      sourced_at: "2023-09-12",
      profile_type: "linkedin",
      notice_period: "1 Month",
      expected_salary: "8 LPA",
    },
  ];

  const candidates2 = [
    {
      id: 1,
      name: "Rahul Sharma",
      experience_in_year: "4 Years",
      location: "Bengaluru",
      current_experience: {
        designation: "Frontend Developer",
        time_period: { startDate: { month: 3, year: 2019 } },
        company_name: "Web Innovations",
        company_details: {
          industries: ["Web Development"],
          staff_range: { start: 100, end: 250 },
        },
        category: "Full-Time",
      },
      previous_experience: {
        designation: "Intern Developer",
        time_period: {
          startDate: { month: 6, year: 2017 },
          endDate: { month: 2, year: 2019 },
        },
        company_name: "Startup Works",
        company_details: {
          industries: ["Tech Startup"],
          staff_range: { start: 20, end: 50 },
        },
        category: "Internship",
      },
      skills: ["HTML", "CSS", "JavaScript", "React", "Redux"],
      sourced_at: "2023-09-12",
      profile_type: "linkedin",
      notice_period: "1 Month",
      expected_salary: "8 LPA",
    },
    {
      id: 2,
      name: "Ajay Mittal Singh",
      experience_in_year: "5 Years",
      location: "Bengaluru",
      current_experience: {
        designation: "Software Engineer",
        time_period: { startDate: { month: 1, year: 2018 } },
        company_name: "Tech Corp",
        company_details: {
          industries: ["Technology"],
          staff_range: { start: 200, end: 500 },
        },
        category: "Full-Time",
      },
      previous_experience: {
        designation: "Junior Developer",
        time_period: {
          startDate: { month: 1, year: 2015 },
          endDate: { month: 12, year: 2017 },
        },
        company_name: "Code Works",
        company_details: {
          industries: ["Software"],
          staff_range: { start: 50, end: 200 },
        },
        category: "Contract",
      },
      skills: ["JavaScript", "React", "Node.js", "CSS", "HTML"],
      sourced_at: "2023-09-10",
      profile_type: "linkedin",
      notice_period: "2 Months",
      expected_salary: "12 LPA",
    },
  ];

  const [candidates, setCandidates] = useState(candidates1);

  const cursorRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const moveCursor = () => {
      if (cursorRef.current) {
        cursorRef.current.style.top = "6%"; // Move to specific top
        cursorRef.current.style.left = "38%"; // Move to specific left
        cursorRef.current.style.zIndex = "1000"; // Ensure it's on top
        setTimeout(() => {
          setJobFilter("R1");
          setCandidates(candidates2);
        }, 1000); // Update filter and candidates after 1 second
      }
    };

    const resetCursor = () => {
      if (cursorRef.current) {
        cursorRef.current.style.top = "6%"; // Reset to original top
        cursorRef.current.style.left = "28%"; // Reset to original left
        cursorRef.current.style.zIndex = "1000"; // Keep it on top
        setTimeout(() => {
          setJobFilter("All");
          setCandidates(candidates1);
        }, 1000); // Reset filter and candidates after 1 second
      }
    };

    // Function to handle the infinite loop behavior
    const infiniteCursorMovement = () => {
      moveCursor(); // Start with moving the cursor
      setTimeout(() => {
        resetCursor(); // Reset after 6 seconds
      }, 6000); // Reset happens 6 seconds after moving
    };

    // Start the infinite loop after 5 seconds delay
    const initialDelay = setTimeout(() => {
      infiniteCursorMovement(); // Start the first cycle
      const intervalId = setInterval(() => {
        infiniteCursorMovement(); // Repeat infinitely every 12 seconds (6 seconds move + 6 seconds reset)
      }, 12000); // 12 seconds for full cycle (6 seconds move + 6 seconds reset)

      return () => clearInterval(intervalId); // Clean up interval on unmount
    }, 2000); // Initial 5 seconds delay before starting the loop

    return () => clearTimeout(initialDelay); // Clean up the initial delay timer
  }, []);

  const items = [
    {
      label: "Interested",
      key: "interested",
    },
    {
      label: "Screen Select",
      key: "shortlisted",
    },
    {
      label: "In Process",
      key: "in_process",
    },
    {
      label: "No Response",
      key: "no_response",
    },
    {
      label: "Candidate Mismatch",
      key: "candidate_mismatch",
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <div className={styles.candidatesCards}>
      <div className={styles.candtidatesMessageActionAllShow}>
        Edit ranking algorithm <RankFilters jobFilter={jobFilter} />
      </div>

      <img
        ref={cursorRef}
        src="./img/animation/cursor1.png"
        alt=""
        width={"2%"}
        style={{
          position: "absolute",
          top: "60%",
          left: "85%",
          zIndex: "1000",
          transition: "top 1s ease, left 1s ease", // Smooth transition
        }}
      />
      {candidates.map((candidate, key) => (
        <div className={styles.candidatesCard} key={key}>
          <div className={styles.candidateCardHeading}>
            <div className={styles.candidateName}>
              <Checkbox />
              <Typography className={styles.candidateCardTitle}>
                {candidate.name}
              </Typography>
            </div>

            <div className={styles.cnadidateStatusContaier}>
              <Dropdown menu={menuProps}>
                <Button style={{ fontSize: "10px", width: "130px" }}>
                  <Space>
                    Candidate Status
                    <DownOutlined style={{ fontSize: "10px", width: "20px" }} />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </div>

          <div className={styles.candidateCardSubHeading}>
            <div className={styles.candidateCardBasicInfoHeading}>
              <img
                className={styles.candidateCardIcon}
                src="./img/animation/experience.jpg"
                alt="Experience"
              />
              <Typography className={styles.candidateCardBasicInfoTitle}>
                {candidate.experience_in_year}
              </Typography>
            </div>

            <div className={styles.candidateCardBasicInfoHeading}>
              <img
                className={styles.candidateCardIcon}
                src="./img/animation/location.jpg"
                alt="Location"
              />
              <Typography className={styles.candidateCardBasicInfoTitle}>
                {candidate.location}
              </Typography>
            </div>

            <div className={styles.candidateCardBasicInfoHeading}>
              <img
                className={styles.candidateCardIcon}
                src="./img/animation/wallet.jpg"
                alt="Location"
              />
              <Typography className={styles.candidateCardBasicInfoTitle}>
                {candidate.expected_salary}
              </Typography>
            </div>

            <div className={styles.candidateCardBasicInfoHeading}>
              <img
                className={styles.candidateCardIcon}
                src="./img/animation/np.jpg"
                alt="Location"
              />
              <Typography className={styles.candidateCardBasicInfoTitle}>
                {candidate.notice_period}
              </Typography>
            </div>
          </div>

          <div className={styles.candidatesDetails}>
            <div className={styles.candidateRow}>
              <div className={styles.candidateRowHeading}>
                <Typography style={{ color: "#808080", fontSize: "10px" }}>
                  Current
                </Typography>
              </div>
              {candidate.current_experience ? (
                <div className={styles.candidateRowDetails}>
                  <Typography style={{ fontSize: "10px" }}>
                    {candidate.current_experience?.designation}{" "}
                    <span style={{ color: "#808080" }}>for </span>5 Years and 8
                    Months
                  </Typography>
                  <div className={styles.candidateJobExperienceData}>
                    <div className={styles.candidateJobExperienceCompany}>
                      <div className={styles.candidatecompany_name}>
                        <span
                          style={{ color: "#808080", paddingRight: "0.25rem" }}
                        >
                          @
                        </span>
                        <Typography.Link
                          style={{
                            textDecoration: "underline",
                            color: "#0778BD",
                            fontSize: "9px",
                          }}
                        >
                          {candidate.current_experience.company_name}
                        </Typography.Link>
                      </div>
                      {candidate.current_experience?.category && (
                        <div>
                          <div className={styles.candidateCompanyType}>
                            {candidate.current_experience?.category}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.candidateIndustryType}>
                      {candidate.current_experience.company_details?.industries?.map(
                        (data, index) => (
                          <span style={{ fontSize: "10px" }} key={index}>
                            {data} |{" "}
                          </span>
                        )
                      )}
                      {candidate.current_experience.company_details
                        ?.staff_range &&
                        `
                        ${candidate.current_experience.company_details?.staff_range.start} 
                        - ${candidate.current_experience.company_details?.staff_range.end} employees`}
                    </div>
                  </div>
                </div>
              ) : (
                "Not Mentioned"
              )}
            </div>

            <div className={styles.candidateRow}>
              <div className={styles.candidateRowHeading}>
                <Typography style={{ color: "#808080", fontSize: "10px" }}>
                  Recent Skill Stack
                </Typography>
              </div>
              <div className={styles.candidateRowDetails}>
                {candidate.skills?.slice(0, 5).join(" | ")}{" "}
                {candidate.skills.length > 5
                  ? `|+${candidate.skills.length - 5} more`
                  : ""}
              </div>
            </div>

            <div className={styles.candidateRow}>
              <div className={styles.candidateRowHeading}>
                <Typography style={{ color: "#808080", fontSize: "10px" }}>
                  Previous
                </Typography>
              </div>
              {candidate.previous_experience ? (
                <div className={styles.candidateRowDetails}>
                  <Typography style={{ fontSize: "10px" }}>
                    {candidate.previous_experience?.designation}{" "}
                    <span style={{ color: "#808080" }}>for </span>2 Years and 11
                    Months
                  </Typography>
                  <div className={styles.candidateJobExperienceData}>
                    <div className={styles.candidateJobExperienceCompany}>
                      <div className={styles.candidatecompany_name}>
                        <span
                          style={{
                            color: "#808080",
                            paddingRight: "0.25rem",
                            fontSize: "9px",
                          }}
                        >
                          @
                        </span>
                        <Typography.Link
                          style={{
                            textDecoration: "underline",
                            color: "#0778BD",
                            fontSize: "10px",
                          }}
                        >
                          {candidate.previous_experience.company_name}
                        </Typography.Link>
                      </div>
                      {candidate.previous_experience?.category && (
                        <div>
                          <div className={styles.candidateCompanyType}>
                            {candidate.previous_experience?.category}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.candidateIndustryType}>
                      {candidate.previous_experience.company_details?.industries?.map(
                        (data, index) => (
                          <span key={index}>{data} | </span>
                        )
                      )}
                      {candidate.previous_experience.company_details
                        ?.staff_range &&
                        `
                        ${candidate.previous_experience.company_details?.staff_range.start} 
                        - ${candidate.previous_experience.company_details?.staff_range.end} employees`}
                    </div>
                  </div>
                </div>
              ) : (
                "Not Mentioned"
              )}
            </div>
          </div>

          <div className={styles.candtidatesMessageAction}>
            <Typography style={{ fontSize: "10px" }}>
              <span style={{ color: "#808080", paddingRight: "0.25rem" }}>
                Sourced
              </span>{" "}
              <span style={{ fontWeight: "500", fontSize: "10px" }}>
                5 days ago
              </span>
            </Typography>

            <Button
              ref={buttonRef}
              type="primary"
              size="large"
              style={{
                height: "20px",
                fontSize: "8px",
                background: "#fff",
                color: "#0778BD",
                border: "1.25px solid #0778BD",
              }}
            >
              Send email
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CandidateCardsLinkedinAnim2;
