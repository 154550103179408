import React, { useState } from "react";
import { Col, Row } from "antd";
import JobsChats from "./JobChats";
import JobCandidiateSection from "./JobCandidiateSection";
import MessageSentPopup from "./MessageSent";
import styles from "./monkbot.module.css";

const Index = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [show, setShow] = useState(1);

  return (
    <div style={{ position: "relative" }}>
      <Row
        style={{
          maxHeight: "100vh",
          maxWidth: "70vw",
          overflow: "hidden",
          border: "1.5px solid #b8b8b8",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <Col
          style={{
            height: "30px",
            backgroundColor: "#353535",
            width: "69.9vw",
            position: "absolute",
            top: "-5%",

            borderRadius: "10px 10px 0 0  ",
          }}
        ></Col>
        <Col>
          <Row
            style={{
              maxHeight: "100vh",
              maxWidth: "75vw",
              overflow: "hidden",
            }}
          >
            <Col
              style={{
                background: "#FFF",
                width: "35%",
                padding: "0",
              }}
            >
              <JobsChats />
            </Col>
            <Col
              style={{
                width: "65%",
                overflow: "scroll",
                background: "#fff",
                overflow: "hidden",
              }}
            >
              {showMessage && (
                <MessageSentPopup
                  setShow={setShow}
                  setShowMessage={setShowMessage}
                />
              )}
              <JobCandidiateSection
                showMessage={showMessage}
                setShowMessage={setShowMessage}
                show={show}
                setShow={setShow}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Index;
