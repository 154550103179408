import React, { useEffect } from "react";
import "./LoginModal.css"; // Custom CSS file
import { useDispatch, useSelector } from "react-redux";
import { setOpenLoginModal, setWaitListFormModal } from "../../../../features/auth2/authSlice2";

const LoginModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.auth2.openLoginModal);

  const closeModal = () => {
    dispatch(setOpenLoginModal(false));
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden"; // Disable scrolling
    } 

    // Cleanup to reset the scroll when the modal is closed or the component is unmounted
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [isOpen]); // This effect depends on the modal's open state

  const handleLogin = () => {
    dispatch(setOpenLoginModal(false));
    dispatch(setWaitListFormModal(true));
  }

  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Sign In to Tabuddy</h2>
              <button onClick={closeModal} className="close-button">
                X
              </button>
            </div>
            <div className="modal-body">
              <h3 className="signinheading">
                Sign in to Unlock Full Access!
              </h3>
              <p>
                By signing in, you'll get exclusive access to View and connect
                with more profiles
              </p>
              <div className="loginButtons">
                <a
                  className="loginButton"
                  type="primary"
                  href={`https://api-superapp.recruitingmonk.com/v1/auth/google/ta-buddy`}
                  onClick={handleLogin}
                >
                  <div>
                    <img src="./img/googleauth.png" alt="" />
                  </div>
                  <div>Sign in with Google</div>
                </a>

                {/* <a
                  className="loginButton"
                  type="primary"
                  href={`https://api-superapp-dev.recruitingmonk.com/v1/auth/outlook`}
                >
                  <div>
                    <img src="./img/outlookauth.png" alt="" width="22px" />
                  </div>
                  <div>Sign in with Outlook</div>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginModal;