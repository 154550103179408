import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import AdminLogin from '../components/Admin/AdminLogin'
import Navbar from '../components/NewDesign/sharedComponents/NavbarComponent/Navbar'
import { useNavigate } from 'react-router-dom'
import AdminTable from '../components/Admin/AdminTable'

const AdminPage = () => {
    const navigate = useNavigate();
    const isAdmin = useSelector((state)=> state.auth2.isAdmin)
    const isAuth = useSelector((state)=> state.auth2.isAuthorized);

    // useEffect(()=>{
    //   if(!isAuth){
    //     navigate("/");
    //   }
    // },[isAuth])

  return (
    <>
    <Navbar />
    {!isAdmin ? <AdminLogin /> : <AdminTable/> }
    </>
  )
}

export default AdminPage